import React from 'react';

function MyAccountTitle() {

  return (
    <div className="row justify-content-sm-center align-items-center">
      <div className="col-12 text-center">
        <h2 className="heading-decorated">Administración de tu cuenta de usuario</h2>
      </div>
    </div>
  );

}

export default MyAccountTitle;