import React, { useState } from 'react';
import { useFormik } from 'formik';
import { readFile, formatPrice, constants } from '../../../utils/common';
import { getOrientation } from 'get-orientation';
import { getRotatedImage } from './crop/utils/rotateImage';
import { ORIENTATION_TO_ANGLE } from './crop/utils/constants';
import CropImage from './crop/CropImage';
import { withRouter } from 'react-router-dom';
import env from '../../../environments';
import { getOrder, setOrder } from '../../../utils/apiLocal';

function ProductDetailImage({ product, history }) {

  const validate = values => {
    const errors = {};

    if (!values.size) {
      errors.size = 'Primero debes seleccionar un tamaño';
    }


    if (!values.userImage) {
      errors.userImage = 'Sube tu propia foto y colocalá a tu gusto';
    }

    if (values.fakeImage && values.fakeImage.type !== 'image/jpeg') {
      errors.fakeImage = 'Formato de imagen no permitido';
    }

    return errors;
  };

  const handleOnSubmit = (values) => {
    const productToLocal = {
      id: product.id,
      description: product.description,
      name: product.name,
      productItem: product.productItems.filter(item => item.id.toString() === values.size)[0],
      numberUnits: 1,
      image: values.croppedImage,
      hanger: values.hanger
    };

    const currentLocalOrder = getOrder();

    currentLocalOrder.items.push(productToLocal);

    setOrder(currentLocalOrder);

    history.push({ pathname: env.BASE_PATH.concat('cart') });
  };

  const formik = useFormik({
    initialValues: {
      size: '',
      userImage: '',
      fakeImage: '',
      croppedImage: '',
      hanger: false,
    },
    validate,
    onSubmit: handleOnSubmit,
  });

  const [uploadedImage, setUploadedImage] = useState(null);

  const onFileChange = async e => {
    formik.handleChange(e);

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      formik.setFieldValue('fakeImage', file);

      if (file.type === 'image/jpeg') {
        let imageDataUrl = await readFile(file);

        // apply rotation if needed
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }

        setUploadedImage(imageDataUrl);
      }
      else {
        setUploadedImage(null);
      }
    }
  };

  return (
    <section className="section-md bg-default decor-text" data-content="Product Detail">
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h4 className="heading-decorated">Sube tu foto preferida</h4>
            <p>Selecciona el tamaño que prefieras, entre los que tenemos disponibles para ti y después sube tu foto preferida
            para adaptarla y colocarla como desees. Recuerda que te aconsejamos que subas fotos de buena calidad para resultados
              increíbles. Podrás previsualizar el resultado final antes de hacer tu compra.</p>
            <form id="productForm" className="rd-mailform rd-mailform_style-1 form-shadow" onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 form-wrap">
                  <label className="form-label-outside" htmlFor="captcha">Tamaños disponibles (ancho x alto)</label>
                  <div className="row">
                    {product.productItems.map((item, index) => (
                      <div key={index} className="col-md-6">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="size" id={'size_'.concat(item.id)}
                            onChange={formik.handleChange} onBlur={formik.handleBlur} value={item.id} />
                          <label className="form-check-label" htmlFor={'size_'.concat(item.id)}>
                            {item.title} - <strong className="post-inline__time">
                              {formatPrice(formik.values.hanger ? item.unitPrice + constants.HANGER_UNIT_PRICE : item.unitPrice)}/Ud.
                          </strong>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                  {formik.errors.size ? <span className="form-validation">{formik.errors.size}</span> : null}
                  <input type="checkbox" name="hanger" id="hanger"
                    onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.hanger} />
                  <label className="form-label-outside" style={{ display: 'inline' }} htmlFor="hanger">
                    &nbsp;¿Desea pedir colgador? (Tendra un precio extra de {formatPrice(constants.HANGER_UNIT_PRICE)})
                        </label>
                </div>
                <div className="col-md-6 form-wrap">
                  <label className="form-label-outside" htmlFor="userImage">Selecciona tu imagen (Sólo permitido formato JPG)</label>
                  <input type="file" className="form-control-file" name="userImage" id="userImage"
                    onChange={onFileChange} onBlur={formik.handleBlur} value={formik.values.userImage} />
                  {formik.errors.userImage ? <span className="form-validation">{formik.errors.userImage}</span> :
                    formik.errors.fakeImage ? <span className="form-validation">{formik.errors.fakeImage}</span> : null}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 text-center mt-5 form-wrap">
                  {uploadedImage && formik.values.size && (
                    <CropImage image={uploadedImage}
                      aspect={product.productItems.filter(item => item.id.toString() === formik.values.size)[0]}
                      onChange={croppedImage => formik.setFieldValue('croppedImage', croppedImage)} />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );

}

export default withRouter(ProductDetailImage);