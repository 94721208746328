import React, { useState } from 'react';
import { useFormik } from 'formik';
import api from '../../../utils/api';

function ChangePasswordForm() {

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorText, setErrorText] = useState(false);

  const validate = values => {
    const errors = {};

    if (!values.oldPassword) {
      errors.oldPassword = 'Tu actual contraseña es obligatoria';
    } else if (values.oldPassword.length < 8) {
      errors.oldPassword = 'Debe tener 8 carcateres o más';
    }

    if (!values.newPassword) {
      errors.newPassword = 'Tu nueva contraseña es obligatoria';
    } else if (values.newPassword.length < 8) {
      errors.newPassword = 'Debe tener 8 caracteres o más';
    }

    if (!values.retryNewPassword) {
      errors.retryNewPassword = 'La confirmación de tu nueva contraseña es obligatoria';
    } else if (values.retryNewPassword !== values.newPassword) {
      errors.retryNewPassword = 'La confirmación de tu nueva contraseña no coincide con tu nueva contraseña';
    }

    return errors;
  };

  const handleOnSubmit = (values) => {
    values.townId = parseInt(values.townId);

    api.post('users/password', {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword
    })
      .then(response => {
        window.scrollTo(0, document.getElementById('ChangePasswordForm').offsetTop + 100);

        if (response.statusCode === 200) {
          setErrorText('');
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 10000);
          formik.resetForm();
        } else {
          setErrorText(response.error.description);
          setShowErrorAlert(true);
          setTimeout(() => {
            setShowErrorAlert(false);
          }, 10000);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      retryNewPassword: ''
    },
    validate,
    onSubmit: handleOnSubmit
  });

  return (
    <>
      <h6 className="heading-decorated">Cambiar contraseña</h6>
      <form id="ChangePasswordForm" className="rd-mailform rd-mailform_style-1 form-shadow" onSubmit={formik.handleSubmit}>
        {showSuccessAlert && <div className="alert alert-success fade show" role="alert">
          <strong>OK!</strong> Tu contraseña ha sido modificada correctamente.
        </div>}
        {showErrorAlert && <div className="alert alert-danger fade show" role="alert">
          <strong>ERROR!</strong> No se ha podido actualizar tu contraseña. Por favor, revisa esto:<br />{errorText}
        </div>}
        <div className="form-wrap form-wrap_icon fa-user-secret">
          <input className="form-input" id="oldPassword" type="password" name="oldPassword" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.oldPassword} />
          {formik.errors.oldPassword ? <span className="form-validation">{formik.errors.oldPassword}</span> : null}
          <label className="form-label" htmlFor="oldPassword">Contraseña actual</label>
        </div>
        <div className="form-wrap form-wrap_icon fa-user-secret">
          <input className="form-input" id="newPassword" type="password" name="newPassword" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.newPassword} />
          {formik.errors.newPassword ? <span className="form-validation">{formik.errors.newPassword}</span> : null}
          <label className="form-label" htmlFor="newPassword">Nueva contraseña</label>
        </div>
        <div className="form-wrap form-wrap_icon fa-user-secret">
          <input className="form-input" id="retryNewPassword" type="password" name="retryNewPassword" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.retryNewPassword} />
          {formik.errors.retryNewPassword ? <span className="form-validation">{formik.errors.retryNewPassword}</span> : null}
          <label className="form-label" htmlFor="retryNewPassword">Confirmar nueva contraseña</label>
        </div>
        <button className="button button-black button-shadow" type="submit">Guardar</button>
      </form>
    </>
  );
}

export default ChangePasswordForm;