import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import env from './environments';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import ProductDetail from './pages/productDetail/ProductDetail';
import Privacity from './pages/Privacity/Privacity';
import Cookies from './pages/Cookies/Cookies';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import ShipmentsAndReturns from './pages/ShipmentsAndReturns/ShipmentsAndReturns';
import Cart from './pages/cart/Cart';
import Login from './pages/login/Login';
import OrderConfirm from './pages/orderConfirm/OrderConfirm';
import PersonalData from './pages/myAccount/PersonalData';
import ChangePassword from './pages/myAccount/ChangePassword';
import MyAccountOrders from './pages/myAccount/MyAccountOrders';
import ConfirmRegistration from './pages/myAccount/ConfirmRegistration';
import OrderConfirmResultOk from './pages/orderConfirmResult/OrderConfirmResultOk';
import OrderConfirmResultKo from './pages/orderConfirmResult/OrderConfirmResultKo';
import AdminOrders from './pages/admin/orders/AdminOrders';
import AdminUsers from './pages/admin/users/AdminUsers';

function App(props) {

  const documentLocation = document.location.pathname.replace('/', '');
  const baseLocation = env.BASE_PATH.replace('/', '');

  if (env.name === 'production' && document.location.origin.indexOf('https://') === -1) {
    window.location = env.baseLocations[0];
  }

  if (documentLocation !== baseLocation) {
    if (documentLocation.indexOf('myAccount/confirmRegistration') === -1 && documentLocation.indexOf('orderConfirmResultOk') === -1
      && documentLocation.indexOf('orderConfirmResultKo') === -1) {
      window.location = env.BASE_PATH;
    }
  }

  return (
    <Router>
      <Switch>
        <Route path={env.BASE_PATH.concat('termsAndConditions')} component={TermsAndConditions} />
        <Route path={env.BASE_PATH.concat('shipmentsAndReturns')} component={ShipmentsAndReturns} />
        <Route path={env.BASE_PATH.concat('cookies')} component={Cookies} />
        <Route path={env.BASE_PATH.concat('privacity')} component={Privacity} />
        <Route path={env.BASE_PATH.concat('product')} component={ProductDetail} />
        <Route path={env.BASE_PATH.concat('contact')} component={Contact} />
        <Route path={env.BASE_PATH.concat('cart')} component={Cart} />
        <Route path={env.BASE_PATH.concat('login')} component={Login} />
        <Route path={env.BASE_PATH.concat('orderConfirm')} component={OrderConfirm} />
        <Route path={env.BASE_PATH.concat('orderConfirmResultOk')} component={OrderConfirmResultOk} />
        <Route path={env.BASE_PATH.concat('orderConfirmResultKo')} component={OrderConfirmResultKo} />
        <Route path={env.BASE_PATH.concat('myAccount/personalData')} component={PersonalData} />
        <Route path={env.BASE_PATH.concat('myAccount/changePassword')} component={ChangePassword} />
        <Route path={env.BASE_PATH.concat('myAccount/orders')} component={MyAccountOrders} />
        <Route path={env.BASE_PATH.concat('myAccount/confirmRegistration')} component={ConfirmRegistration} />
        <Route path={env.BASE_PATH.concat('admin/orders')} component={AdminOrders} />
        <Route path={env.BASE_PATH.concat('admin/users')} component={AdminUsers} />
        <Route path={env.BASE_PATH} component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
