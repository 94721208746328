/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import data from '../staticData';
import env from '../environments';
import { getOrder, setUser, getUser, hasRol } from '../utils/apiLocal';
import api from '../utils/api';
import environments from '../environments';

function Header({ isHome, isContact, isAdmin, history }) {
  let navbarWrap = '';
  let navClassName = 'rd-navbar rd-navbar-default';
  let navDataLgDeviceLayout = 'rd-navbar-static';
  let navDataBodyClass = '';
  let topPanelClassName = 'rd-navbar-top-panel rd-navbar-top-panel-dark';
  let activeHomeMenuClassName = '';
  let activeContactMenuClassName = '';

  if (isHome) {
    navbarWrap = 'rd-navbar-wrap';
    navClassName = 'rd-navbar rd-navbar_transparent rd-navbar_boxed';
    navDataLgDeviceLayout = 'rd-navbar-fixed';
    navDataBodyClass = 'rd-navbar-absolute';
    topPanelClassName = 'rd-navbar-top-panel';
    activeHomeMenuClassName = 'active';
  } else if (isContact) {
    activeContactMenuClassName = 'active';
  }

  const cartProducts = getOrder().items;
  const [userSession, setUserSession] = useState(getUser());

  const handleOnClickLogout = () => {
    api.get('users/logout').then(response => {
      if (response.statusCode === 200) {
        setUser(null);
        setUserSession(null);
        history.push({ pathname: environments.BASE_PATH });
      }
    });
  };

  return (
    <header className="page-header">
      <div className={navbarWrap}>
        <nav className={navClassName} data-layout="rd-navbar-fixed"
          data-sm-layout="rd-navbar-fixed" data-sm-device-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed"
          data-md-device-layout="rd-navbar-fixed" data-lg-device-layout={navDataLgDeviceLayout}
          data-lg-layout="rd-navbar-static" data-xl-device-layout="rd-navbar-static" data-xl-layout="rd-navbar-static"
          data-xxl-device-layout="rd-navbar-static" data-xxl-layout="rd-navbar-static" data-stick-up-clone="false"
          data-sm-stick-up="true" data-md-stick-up="true" data-lg-stick-up="true" data-md-stick-up-offset="115px"
          data-lg-stick-up-offset="35px" data-body-class={navDataBodyClass}>
          <div className={topPanelClassName}>
            <div className="rd-navbar-top-panel__main">
              <div className="rd-navbar-top-panel__toggle rd-navbar-fixed__element-1 rd-navbar-static--hidden"
                data-rd-navbar-toggle=".rd-navbar-top-panel__main">
                <span></span>
              </div>
              <div className="rd-navbar-top-panel__content">
                <div className="rd-navbar-top-panel__left">
                  <p className={isHome ? 'color1' : 'color4'}><strong>Tienda online de cuadros personalizados</strong></p>
                  {/* <ul className="rd-navbar-items-list">
                    <li>
                      <div className="unit flex-row align-items-center unit-spacing-xs">
                        <div className="unit__left">
                          <span className="icon icon-sm icon-primary linear-icon-map-marker"></span>
                        </div>
                        <div className="unit__body">
                          <p>
                            <a target="_blank" rel="noopener noreferrer" title={data.address.title}
                              href={data.address.url}>Dirección: {data.address.name}</a>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="unit flex-row align-items-center unit-spacing-xs">
                        <div className="unit__left">
                          <span className="icon icon-sm icon-primary linear-icon-telephone"></span>
                        </div>
                        <div className="unit__body">
                          <ul className="list-semicolon">
                            <li>
                              <a href={data.phone.url} title={data.phone.title}>{data.phone.name}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul> */}
                </div>
                <div className="rd-navbar-top-panel__right">
                  <ul className="list-inline-xxs">
                    <li>
                      <a className="icon icon-xxs icon-gray-darker fa fa-facebook" target="_blank" rel="noopener noreferrer"
                        href={data.social.facebook.url} title={data.social.facebook.title}></a>
                    </li>
                    <li>
                      <a className="icon icon-xxs icon-gray-darker fa fa-youtube" target="_blank" rel="noopener noreferrer"
                        href={data.social.youtube.url} title={data.social.youtube.title}></a>
                    </li>
                    <li>
                      <a className="icon icon-xxs icon-gray-darker fa fa-instagram" target="_blank" rel="noopener noreferrer"
                        href={data.social.instagram.url} title={data.social.instagram.title}></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="rd-navbar-inner rd-navbar-search-wrap">
            <div className="rd-navbar-panel rd-navbar-search-lg_collapsable">
              <button className="rd-navbar-toggle" data-rd-navbar-toggle=".rd-navbar-nav-wrap">
                <span></span>
              </button>
              <div className="rd-navbar-brand">
                <Link className="brand-name" to={env.BASE_PATH}
                  title="KUADRUKIS te ofrece varios materiales de la mejor calidad para personalizar e imprimir tus mejores fotos.">
                  <img src={env.BASE_ASSETS_URL.concat('images/logo.png')} width="355" height="67"
                    alt="KUADRUKIS te ofrece varios materiales de la mejor calidad para personalizar e imprimir tus mejores fotos." />
                </Link>
              </div>
            </div>
            <div className="rd-navbar-nav-wrap rd-navbar-search_not-collapsable">
              <div className="rd-navbar-search_collapsable">
                <ul className="rd-navbar-nav">
                  <li className={activeHomeMenuClassName}>
                    {activeHomeMenuClassName.length === 0 && <Link to={env.BASE_PATH} title="Ir a la página principal">Inicio</Link>}
                    {activeHomeMenuClassName.length > 0 && <a href="#home" title="Ir a la página principal"
                      data-custom-scroll-to="home">Inicio</a>}
                  </li>
                  {!isAdmin && (
                    <li>
                      {activeHomeMenuClassName.length === 0 && <Link to={env.BASE_PATH.concat('#products')} title="Ver todos nuestros productos">
                        Productos</Link>}
                      {activeHomeMenuClassName.length > 0 && <a href="#products" title="Ver todos nuestros productos"
                        data-custom-scroll-to="products">Productos</a>}
                    </li>
                  )}
                  {!isAdmin && (
                    <li>
                      {activeHomeMenuClassName.length === 0 && <Link to={env.BASE_PATH.concat('#about')} title="Ver más información sobre quienes somos">
                        Quienes Somos</Link>}
                      {activeHomeMenuClassName.length > 0 && <a href="#about" title="Ver más información sobre quienes somos"
                        data-custom-scroll-to="about">Quienes Somos</a>}
                    </li>
                  )}
                  {!isAdmin && (
                    <li className={activeContactMenuClassName}>
                      <Link to={env.BASE_PATH.concat('contact')} title="Ponte en contacto con nosotros o revisa nuestra situación">Contacto</Link>
                    </li>
                  )}
                  {!isAdmin && (
                    <li>
                      {activeHomeMenuClassName.length === 0 && <Link to={env.BASE_PATH.concat('#inspiration')} title="Ver ideas para inspirarte">
                        Inspiración</Link>}
                      {activeHomeMenuClassName.length > 0 && <a href="#inspiration" title="Ver ideas para inspirarte"
                        data-custom-scroll-to="inspiration">Inspiración</a>}
                    </li>
                  )}
                  {isAdmin && (
                    <li>
                      <Link to={env.BASE_PATH.concat('admin/orders')} title="Administración de Pedidos">Ad. Pedidos</Link>
                    </li>
                  )}
                  {isAdmin && (
                    <li>
                      <Link to={env.BASE_PATH.concat('admin/users')} title="Administración de Usuarios">Ad. Usuarios</Link>
                    </li>
                  )}
                  <li>
                    <ul className="list-inline-md">
                      <li>
                        <Link className="icon-md mdi mdi-cart" to={env.BASE_PATH.concat('cart')} title="Ver mi carrito de la compra actual">
                          {cartProducts.length > 0 && (
                            <span className="badge badge-danger">{cartProducts.length}</span>
                          )}
                        </Link>
                      </li>
                      {userSession === null && (
                        <li>
                          <Link className="icon-md mdi mdi-login" to={env.BASE_PATH.concat('login')} title="Iniciar sesión"></Link>
                        </li>
                      )}
                      {userSession !== null && (
                        <li>
                          <Link className="icon-md mdi mdi-account-circle" to={env.BASE_PATH.concat('myAccount/personalData')} title="Acceder a mi cuenta"></Link>
                        </li>
                      )}
                      {userSession !== null && (
                        <li>
                          <button className="button-link icon-md mdi mdi-logout" onClick={handleOnClickLogout} title="Cerrar mi sesión"></button>
                        </li>
                      )}
                      {userSession !== null && hasRol('ADMIN') && !isAdmin && (
                        <li>
                          <Link className="button-link icon-md mdi mdi-apps" to={env.BASE_PATH.concat('admin/orders')} title="Administración"></Link>
                        </li>
                      )}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

Header.propTypes = {
  isHome: PropTypes.bool.isRequired,
  isContact: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  isHome: false,
  isContact: false,
  isAdmin: false,
};

export default withRouter(Header);