import React from 'react';
import env from '../../../environments';

function ProductDetailInfo({ product }) {

  let images = [];

  switch (product.id) {
    case 1:
      images = [
        { thumbnail: '1-1-thumb.jpg', big: '1-1.jpg' },
        { thumbnail: '1-2-thumb.jpg', big: '1-2.jpg' },
        { thumbnail: '1-3-thumb.jpg', big: '1-3.jpg' }
      ];
      break;
    case 2:
      images = [
        { thumbnail: '2-1-thumb.jpg', big: '2-1.jpg' },
        { thumbnail: '2-2-thumb.jpg', big: '2-2.jpg' },
        { thumbnail: '2-3-thumb.jpg', big: '2-3.jpg' },
        { thumbnail: '2-4-thumb.jpg', big: '2-4.jpg' },
        { thumbnail: '2-5-thumb.jpg', big: '2-5.jpg' }
      ];
      break;
    case 3:
      images = [
        { thumbnail: '3-1-thumb.jpg', big: '3-1.jpg' },
        { thumbnail: '3-2-thumb.jpg', big: '3-2.jpg' },
        { thumbnail: '3-3-thumb.jpg', big: '3-3.jpg' }
      ];
      break;
    case 4:
      images = [
        { thumbnail: '4-1-thumb.jpg', big: '4-1.jpg' },
        { thumbnail: '4-2-thumb.jpg', big: '4-2.jpg' },
        { thumbnail: '4-3-thumb.jpg', big: '4-3.jpg' },
        { thumbnail: '4-4-thumb.jpg', big: '4-4.jpg' }
      ];
      break;
    case 5:
      images = [
        { thumbnail: '5-1-thumb.jpg', big: '5-1.jpg' },
        { thumbnail: '5-2-thumb.jpg', big: '5-2.jpg' },
        { thumbnail: '5-3-thumb.jpg', big: '5-3.jpg' }
      ];
      break;
    default:
      images = [];
      break;
  }

  return (
    <section className="section-md bg-default decor-text" data-content="Product Detail">
      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <h4 className="heading-decorated">Detalles del producto</h4>
            <h6>{product.name}</h6>
            <p dangerouslySetInnerHTML={{ __html: product.description.split("\n").join("<br /><br />") }} />
          </div>
          <div className="col-sm-4">
            <div id="carousel-example" className="carousel slide" data-ride="carousel">
              <div className="carousel-inner row w-100 mx-auto" role="listbox">
                {images.map((img, index) => (
                  <div key={index} className="carousel-item mr-2 col-12 col-sm-8 col-md-6 col-lg-5 active">
                    <a href={env.BASE_ASSETS_URL.concat(`images/products/${img.big}`)} data-lightgallery="item"
                      title={product.name.concat(` - ${index + 1}`)}>
                      <img src={env.BASE_ASSETS_URL.concat(`images/products/${img.thumbnail}`)} className="img-fluid mx-auto d-block"
                        alt={product.name.concat(` - ${index + 1}`)} />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductDetailInfo;