import React from 'react';

function CookiesDetail() {
  return (
    <section className="section-md bg-gray-lighter decor-text" data-content="Privacity">
      <div className="container">
        <div className="row justify-content-lg-center">
          <div className="col-xs-12 text-center">
            <h4 className="heading-decorated">Detalles de nuestra política de cookies</h4>
          </div>
        </div>
        <div className="row justify-content-lg-left">
          <div className="col-xs-12">
            <p className="font-italic">Cookies  Es Un fichero Que se Descarga en su Ordenador Al acceder a este determinadas
            paginas web. Las galletas permiten una tela Una page, Entre Otras Cosas, Almacenar y
            Recuperar Información Sobre los Hábitos de navegación de la ONU usuario o de su equipo
            y, dependiendo de la Información Que contengan y de la forma En que utilice su equipo,
            pueden utilizarse para Reconocer al usuario. . El Navegador del Usuario memoriza las
            cookies en el disco de duro Solamente Durante la Sesión real ocupando espacio de
            memoria de la ONU Mínimo y no perjudicando al Ordenador. Las galletas no contienen
            Ninguna clase de información personal Específica, y La Mayoría de las Mismas se Borran
               del Disco Duro al Finalizar la Sesión de Navegador (las denominadas galletas de Sesión).</p>
            <p>Utilizamos las cookies para facilitar el USO de our website web.Las galletas hijo pequeños
            Ficheros de información Que Nos permiten Compare y entendre CÓMO Nuestros Usuarios navegan
            un Través de Nuestra Página Web, y of this Manera Poder Mejorar consecuentemente El Proceso
            de navegación. Las utilizamos las cookies Que No se almacenan Dato Alguno personal, ni
            ningún tipo de información Que Pueda identificarle. En Caso de no querer galletas Recibir,
            por favor configure Su navegador de Internet Para Que las borre del Disco Duro de su
            Ordenador, las bloquée o le avise en Caso de Instalación de las Mismas. Para continuar el
            pecado: cambios en la configuracion de Las cookies, simplemente Continuación En La Página
              Web. PUEDES Obtener Más información Sobre las galletas Y Su USO en  www.aboutcookies.org.</p>
            <h6>TIPOS DE GALLETAS UTILIZADAS</h6>
            <p className="post-inline__time">1. Las cookies de Rendimiento</p>
            <p>Se Trata de cookies, busque Tratadas Por Nosotros o por Terceros, Que recogen Información
            Sobre Como Se utilizació El Sitio Web (POR EJEMPLO, Las Páginas Que una visita o si se Error
            ALGÚN productos) y Que Nos Ayudan a la Localizar y Solucionar Problemas, cuantificar El Número
            de Usuarios o Medir utilizacion del Sitio web. Toda La Información Recogida Totalmente es
            anónima y Ayuda un entendre CÓMO Funciona Nuestro sitio, Realizando las Mejoras oportunas para
              facilitar su navegación.</p>
            <p>Como utilizar Estas galletas permitira:</p>
            <p>Que Usted navegue por el sitio Y Que una vez su, podamos recompilar Información Sobre CÓMO
            utilizació Usted La Página Web, párrafo Así entendre la usabilidad del sitio, Y ayudarnos a
            implementar las Mejoras necesarias. Estas galletas sin recogerán NINGUNA INFORMACION SOBRE
            USTED Que Pueda Ser USADA multas estafadores publicitarios, o information about de Sus
              preferencias (cuentos de Como SUS Datos de Usuario) Más allá de esa visita en particular.</p>
            <p className="post-inline__time">2. Cookies Funcionales</p>
            <p>Nuestro propósito m Con Estas galletas ES Mejorar la Experiencia de los Usuarios. Se utilizan
            para recordar Ciertos Parámetros de configuration o para proporcionar Ciertos Servicios o
            Mensajes Que pueden v llegar a mejorar su Experiencia en Nuestro Sitio. Por Ejemplo, recuerdan
            El País o El idioma Que ha Seleccionado al Visitar las Páginas, Y No Se utilizan estafadores
              multas de comercialización.</p>
            <p>Como utilizar Estas galletas permitira:</p>
            <p>Recordar SUS Datos de Inicio de Sesión Como cliente al volver a pagina. Nunca recogerán
            Información Sobre Usted Alguna Que Pueda Ser multas USADA estafadores publicitarios, o
            information about de Sus preferencias (cuentos de Como SUS Datos de Usuario) Más allá de esa
              visita en particular.</p>
            <p className="post-inline__time">3. Cookies de marketing</p>
            <p>Son galletas Gestionadas POR Terceros. SE envian AL Equipo Terminal del Usuario from ONU
            equipo o Dominio Que No es gestionado por el editor, entre China: Por otra entidad Que Trata
            los Datos obtenidos a traves de las cookies. : Por ello, PUEDE Como utilizar las Herramientas
            de Terceros Estós párr restringir · EL USO de dichas cookies. Por Ejemplo, ALGUNAS de ellas se
            utilizan para enlazar una de otras paginas web Que proporcionan determinados Servicios para
            visualizar o descargar vídeos, compartir Contenidos en redes sociales (Facebook, Twitter o
            Google). Y en Otros Casos, podran: Además Modificar los anuncios de Otras telas para adaptarlos
              a SUS preferencias.</p>
            <p>Como utilizar Estas galletas permitira:</p>
            <p>Enlazar con redes sociales, ver o descargar vídeos, Obtener Información Sobre su visita un
            sitio web es una traducción Útil para Adaptar anuncios Que le mostrarán En otras este sitios o
            paginas web. Asímismo Es Posible Que al Visitar Alguna Página Web o al abrir ALGÚN correo
            electrónico Donde se publique ALGÚN Anuncio O Alguna promoción Sobre Nuestros Productos o
            Servicios se Instale en su Navegador Alguna galletas Que Nos SIRVA para mostrarle
            posteriormente publicidad Relacionada con la busqueda Que Haya Realizado, desarrollar ONU el
            control de Nuestros Anuncios en Relación, por Ejemplo, Con El Número de Veces Que Vistos hijo,
              Dónde Aparecen, una que hora se ven, etc.</p>
            <p className="post-inline__time">- Las cookies de análisis:</p>
            <p className="font-italic">Son Aquellas que bien Tratadas Por Nosotros o por Terceros, nos
            permiten cuantificar El Número de Usuarios y asi Realizar la Medición y análisis estadístico de
            la utilizacion Que Hacen los Usuarios del Servicio ofertado. Para Ello se Analiza su navegación
            en la web our website con el Fin de Mejorar la oferta de Productos o Servicios Que le OFRECEMOS.
            En concreto, este Sitio Web utilizació Google Analytics, la ONU servicio analítico de web
            prestado por Google, Inc.</p>
            <h6>Revocación Y Eliminación DE COOKIES</h6>
            <p>Usted PUEDE permitir, bloquear o ELIMINAR las galletas instaladas en su equipo Mediante la
            configuration de las OPCIONES del Navegador
            Instalado en su Ordenador, en Caso Que No permita la instalacion de las cookies en su Navegador
            Es Posible Que No Pueda acceder a este Alguna de las Secciones de Nuestra web.</p>
            <p>Administrar las cookies Para Conocer Como en el Navegador, por favor Visite Los Enlaces Siguientes:</p>
            <ul>
              <li>
                Firefox desde <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we"
                  target="_blank" rel="noopener noreferrer">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a>
              </li>
              <li>
                Chrome desde <a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647"
                  target="_blank" rel="noopener noreferrer">http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</a>
              </li>
              <li>
                Explorador desde <a href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"
                  target="_blank" rel="noopener noreferrer">http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</a>
              </li>
              <li>
                Safari desde <a href="http://support.apple.com/kb/ph5042"
                  target="_blank" rel="noopener noreferrer">http://support.apple.com/kb/ph5042</a>
              </li>
            </ul>
            <p>Además, si DESEA Más información Sobre la USO Qué hacemos de las cookies, ENVIENOS UN e-mail a:
              <a href="mailto:info@ingenia.ink" title="Enviar correo electrónico a esta dirección">info@ingenia.ink</a></p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CookiesDetail;