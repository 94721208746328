/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Link } from 'react-router-dom';
import env from '../../../../environments';
import api from '../../../../utils/api';
import { timestampToDatetime, formatPrice } from '../../../../utils/common';
import ModalOrderDetail from './ModalOrderDetail';
import ModalConfirmNewStatus from './ModalConfirmNewStatus';

const GridOrders = forwardRef((props, ref) => {

  const defaultData = {
    page: 1,
    rowsPage: 10,
    columnOrder: 'orderedTimestamp',
    order: 'DESC',
    filters: [{
      name: 'status',
      type: '!=',
      value: "CREATED"
    }],
    totalPages: [],
    resultData: []
  };

  const [data, setData] = useState(defaultData);
  const [orderId, setOrderId] = useState(null);
  const [order, setOrder] = useState(null);

  const reloadOrdersData = () => {
    api.post('orders', data)
      .then(response => {
        if (response.statusCode === 200) {
          const totalPages = Math.ceil(response.data.totalRows / response.data.rowsPage);
          const totalPagesArray = [];

          for (let i = 1; i <= totalPages; i++) {
            totalPagesArray.push(i);
          }

          response.data.totalPages = totalPagesArray;

          response.data.columnOrder = data.columnOrder;
          response.data.order = data.order;
          response.data.filters = data.filters;

          setData(response.data);
        } else {
          setData(defaultData);
        }
      });
  };



  useEffect(() => {
    reloadOrdersData();
  }, []);

  useImperativeHandle(ref, () => ({
    search(searchValues) {
      const currentFilters = [];

      if (searchValues.userId !== '') {
        currentFilters.push({
          name: 'lastName',
          type: 'like',
          value: `%${searchValues.userId}%`
        });
      }

      if (searchValues.status !== '') {
        currentFilters.push({
          name: 'status',
          type: '=',
          value: searchValues.status
        });
      } else {
        currentFilters.push(defaultData.filters[0]);
      }

      if (searchValues.redsysId !== '') {
        currentFilters.push({
          name: 'redsysId',
          type: '=',
          value: searchValues.redsysId
        });
      }

      data.filters = currentFilters;
      data.page = 1;

      setData(data);
      reloadOrdersData();
    }
  }));

  const getOrderDate = (orderData) => {
    let datetime = {};

    datetime = timestampToDatetime(orderData.orderedTimestamp);

    return `${datetime.date} ${datetime.time}`;
  };

  const getOrderStatus = (orderData) => {
    let title = '';

    switch (orderData.status) {
      case 'IN_PROGRESS':
        title = 'En Proceso';
        break;
      case 'SENDED':
        title = 'Enviado';
        break;
      case 'DELIVERED':
        title = 'Entregado';
        break;
      case 'ORDERED':
      default:
        title = 'Pagado';
        break;
    }

    return title;
  };

  const refreshGrid = () => {
    setData(data);
    reloadOrdersData();
  }

  const handleOnPreviousButton = () => {
    data.page = data.page - 1;
    refreshGrid();
  };

  const handleOnNextButton = () => {
    data.page = data.page + 1;
    refreshGrid();
  };

  const handleOnPageButton = page => {
    data.page = page;
    refreshGrid();
  };

  const handleColumnSort = (fieldNameToSort) => {
    if (data.columnOrder === fieldNameToSort) {
      data.order = data.order === 'DESC' ? 'ASC' : 'DESC';
    } else {
      data.order = 'ASC';
    }

    data.columnOrder = fieldNameToSort;
    data.page = 1;

    refreshGrid();
  };

  return (
    <div className="col-md-12 mt-3">
      <table className="table table-striped table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por Id. Pedido" onClick={() => handleColumnSort('id')}>
                # {data.columnOrder === 'id' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por cliente" onClick={() => handleColumnSort('userId')}>
                Cliente {data.columnOrder === 'userId' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por estado" onClick={() => handleColumnSort('status')}>
                Estado {data.columnOrder === 'status' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por fecha" onClick={() => handleColumnSort('orderedTimestamp')}>
                Fecha {data.columnOrder === 'orderedTimestamp' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por id. de TPV" onClick={() => handleColumnSort('redsysId')}>
                Id. TPV {data.columnOrder === 'redsysId' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por total" onClick={() => handleColumnSort('totalAmount')}>
                Total {data.columnOrder === 'totalAmount' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" title="Acciones disponibles por pedido">
                Acciones
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.resultData.map((item, index) => (
            <tr key={index}>
              <th scope="row">{item.id}</th>
              <td>
                <Link className="" to={{
                  pathname: env.BASE_PATH.concat('admin/users/'),
                  params: { userId: item.userId }
                }} title="Ver detalle del cliente">{`(${item.userId}) ${item.firstName} ${item.lastName}`}</Link>
              </td>
              <td>{getOrderStatus(item)}</td>
              <td>{getOrderDate(item)}</td>
              <td>{item.redsysId}</td>
              <td>{formatPrice(item.totalAmount)}</td>
              <td>
                <a href="#orderDetailModal" className="button-link icon-md mdi mdi-information" data-toggle="modal" data-target="#orderDetailModal"
                  title="Ver detalles del pedido" onClick={() => setOrderId(item.id)}>&nbsp;</a>
                {item.status !== 'DELIVERED' && (
                  <a href="#orderConfirmStatusModal" className="button-link icon-md mdi mdi-play-circle" data-toggle="modal" data-target="#orderConfirmStatusModal"
                    title="Avanzar de estado el pedido" onClick={() => setOrder(item)}>&nbsp;</a>
                )}
              </td>
            </tr>
          ))}
          {data.resultData.length === 0 && (
            <tr>
              <td colSpan="6">
                <div className="alert alert-danger fade show" role="alert">
                  No se han encontrado pedidos que mostrar para la búsqueda realizada.
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${data.page === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" tabIndex="-1" onClick={handleOnPreviousButton}>Anterior</a>
          </li>
          {data.totalPages.map((p, index) => (
            <li key={index} className={`page-item ${data.page === p ? 'active' : ''}`}>
              <a className="page-link" href="#" onClick={() => data.page !== p ? handleOnPageButton(p) : null}>
                {p} {data.page === p && (<span className="sr-only">(current)</span>)}
              </a>
            </li>
          ))}
          <li className={`page-item ${data.page === data.totalPages.length ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={handleOnNextButton}>Siguiente</a>
          </li>
        </ul>
      </nav>
      <ModalOrderDetail orderId={orderId} />
      <ModalConfirmNewStatus order={order} onExit={reloadOrdersData} />
    </div>
  );
});

export default GridOrders;