import React from 'react';

function ShipmentsAndReturnsDetail() {
  return (
    <section className="section-md bg-gray-lighter decor-text" data-content="Privacity">
      <div className="container">
        <div className="row justify-content-lg-center">
          <div className="col-xs-12 text-center">
            <h4 className="heading-decorated">INFORMACIÓN DE ENVÍOS Y DEVOLUCIONES</h4>
          </div>
        </div>
        <div className="row justify-content-lg-left">
        <div className="col-xs-12">
        <h6>Envío</h6>
        <p className="post-inline__time">Gastos de envío:</p>
            <p>En todos los productos, los gastos de envío serán GRATIS, solamente aplicables en la península.</p>
            <p>Kuadrukis no realiza entregas a Ceuta, Melilla, Islas Baleares y Canarias.
               Para estos casos pueden consultar condiciones en info@kuadrukis.com</p>
            <p>Kuadrukis envía los pedidos a sus clientes a través de la empresa de logística Correos Express,
               empresa de transporte de reconocido prestigio.</p>
        <p className="post-inline__time">Plazos de Entrega:</p>
            <p>Nuestros plazos de producción y entrega para a España (sólo península) se producen en días
               laborables ( de lunes a viernes):</p>
            <p><u>FOTOS EN PVC - FOTOS EN PVC LIGERO - FOTOS EN ALUMINIO - FOTOS EN MADERA</u></p>
            <p>Producción: 1 día laboral. Pedido realizado antes de las 12:00 horas.</p>
            <p>Entrega: 2-3 días laborables.</p>
            <p>TOTAL: 3-4 días laborables.</p>
            <p><u>FOTOS EN METACRILATO</u></p>
            <p>Producción: 1-3 día laborales. Pedido realizado antes de las 12:00 horas.</p>
            <p>Entrega: 2-3 días laborables.</p>
            <p>TOTAL: 3-6 días laborables.</p>
            <p>La fecha contara a partir del primer día laborable una vez que se haya confirmado el pago.</p>
            <p>Kuadrukis hace todo lo posible para respetar los plazos de entrega, sin embargo, no se hace
               responsable de las consecuencias debidas a un retraso de envío o a una pérdida del paquete por
                parte de personas ajenas al contrato de transporte. Los plazos de transporte se pueden ver
                 alterados por incidencias extraordinarias en el transportista y por dificultades en la entrega
                  de la mercancía. *El plazo de entrega puede aumentar en épocas concretas del año
                   (Black Friday, Navidad, Día de la Madre/ Padre, etc..), por saturación de carga de trabajo
                    de la empresa de transporte, así como por otras posibles causas de fuerza mayor.</p>
            <p>Los errores atribuibles al transporte del producto (golpes y daños visibles a simple vista)
               deben ser notificados dentro de las 24 horas siguientes a su recepción.</p>
               <p>Los errores atribuibles al plazo de entrega por parte de la empresa encargada del transporte
                  del producto, en ningún caso serán atribuibles a Kuadrukis. En caso de negligencia por parte
                   de la empresa del transporte con respecto a dicho plazo establecido, Kuadrikis, una vez
                    revisado el caso, procederá a su estudio.</p>
        <h6>Devoluciones</h6>
            <p>Si existe algún error en el envío, los gastos de devolución corren a cargo de Kuadrukis.
               La sustitución del producto se realizará tan pronto lo comunique el cliente y sin cargo alguno.</p>
            <p>Si tu pedido llega dañado o con un defecto de fabricación, háznoslo saber a través del email:
                    <a href="mailto:info@kuadrukis.com" title="Enviar correo electrónico a esta dirección">info@kuadrukis.com</a></p>
            <p>En virtud de la legislación vigente el derecho de desistimiento no será aplicable a los contratos que se refieran
               al suministro de bienes confeccionados conforme a las especificaciones del consumidor y usuario o claramente
                personalizados, como es el caso de nuestros productos. Por este motivo, Kuadrukis no aceptará devoluciones
                 que no se correspondan con las descritas en esta política de devoluciones.  </p>
            <p>Los productos de Kuadrukis están realizados individualmente por profesionales de la industria de la mecanización
               de piezas y de la impresión, en el que ponen todo su conocimiento y cuidado posible en su realización, pese a ello
                pueden existir casos en los que el producto final presente alguna característica que no es de total agrado del cliente.</p>
            <p>Por múltiples razones, pueden existir variaciones de color en la impresión de un mismo producto personalizado.
               Estas pequeñas diferencias de color no se consideran un error de producción. Por otro lado, la consecución de un color
                preciso depende de la calibración de su monitor y el ambiente en el que trabaja. Además debería usar los perfiles
                 de salida correctos. Todo este proceso se considera propio de un producto profesional y queda fuera del ámbito del servicio
                  estándar de kuadrukis. Por tanto, en cualquier caso y como norma general, no se aceptan devoluciones por temas relacionados con el color.</p>
            <p>Nuestros productos son personalizados y kuadrukis no los editará, corregirá o modificará en modo alguno.
               Es la responsabilidad del cliente revisarlo antes de enviarlo.</p>
        <p className="post-inline__time">RECOMENDACIONES</p>
        <ul>
          <li className="li-disc">Nuestro sistema avisa al usuario de las imágenes que no cumplen con un mínimo de requerimiento de resolución
           y además de realizar un previo del encuadre deseado, que dando este al criterio del cliente. No se aceptarán devoluciones de productos
            por baja resolución, definición de las imágenes o encuadre.</li>
          <li className="li-disc">La representación del producto en pantalla es aproximada y puede haber una diferencia de varios milímetros en la fabricación
             posterior del mismo. No se considerará una diferencia de hasta 5mm entre la representación en pantalla y el resultado final del mismo.</li>
          <li className="li-disc">La determinación del plazo de entrega comienza desde que el producto personalizado está listo y correcto para imprimirse
             hasta el envío del mismo al cliente. Por tanto, no se considerara fuera de plazo de entrega, aquellos que sufran retrasos por no estar
              inicialmente, al recibirse, listos o correctos para su producción y hayan necesitado, por parte del usuario, algún tipo de corrección posterior.</li>
        </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ShipmentsAndReturnsDetail;