import React from 'react';
import loadjs from 'loadjs';
import env from '../../../environments';
import Header from '../../../commons/Header';
import Footer from '../../../commons/Footer';
import GridOrders from './partials/GridOrders';
import SearchOrders from './partials/SearchOrders';

class AdminOrders extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.gridOrdersRef = null;

    this.handleOnSearch = this.handleOnSearch.bind(this);
  }

  componentDidMount() {
    loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
      window.scrollTo(0, 0);
    });
  }

  handleOnSearch(formValues) {
    this.gridOrdersRef.search(formValues);
  }

  render() {
    return (
      <div className="page">
        <Header isAdmin />
        <section className="section-xs bg-default text-md-left decor-text" data-content="Pedidos">
          <div className="container">
            <div className="row justify-content-sm-center align-items-center">
              <div className="col-12 text-center">
                <h2 className="heading-decorated">Administración de Pedidos</h2>
              </div>
            </div>
            <div className="row justify-content-sm-left">
              <SearchOrders onSearch={this.handleOnSearch} />
              <GridOrders ref={(ref) => this.gridOrdersRef = ref} />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default AdminOrders;