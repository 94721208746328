import $ from 'jquery';
import env from '../environments';
import { objectToQueryString } from './common';

const handleFirstThenToFile = (response) => {
  if (!response.ok) {
    throw response;
  }

  return response;
};

const handleFirstThenToJson = (response) => {
  if (!response.ok) {
    throw response;
  }

  return response.json();
};

const handleFirstCatch = (error) => {
  if (typeof error.json === 'function') {
    return error.json()
      .then(response => {
        return response;
      })
      .catch(() => {
        return {
          statusCode: error.status
        };
      });
  }
};

const handleFinally = () => {
  $(".preloader").fadeOut('slow');
}

const customFetch = (method = 'GET', partialUrl, body = '', typeResponse = 'text/json') => {
  $(".preloader").fadeIn('slow');

  const newConfigObject = {
    method,
    credentials: 'include'
  };


  if (body.length > 0) {
    newConfigObject.body = body;
  }

  let promise = fetch(env.API_URL.concat(partialUrl), newConfigObject);

  switch (typeResponse) {
    case 'image/png':
    case 'application/pdf':
      promise = promise.then(handleFirstThenToFile);
      break;
    case 'text/json':
    default:
      promise = promise.then(handleFirstThenToJson);
      break;
  }

  return promise
    .catch(handleFirstCatch)
    .finally(handleFinally)
};

const api = {
  get: (partialUrl, params = {}, typeResponse = 'text/json') => {
    const stringParams = objectToQueryString(params);

    if (stringParams.length > 0) {
      partialUrl += '?'.concat(stringParams);
    }

    return customFetch('GET', partialUrl, '', typeResponse);
  },
  post: (partialUrl, body = {}, typeResponse = 'text/json') => {
    body = JSON.stringify(body);

    return customFetch('POST', partialUrl, body, typeResponse);
  },
  responseHandler: (response, idMainElement, successCallback, errorCallback) => {
    window.scrollTo(0, document.getElementById(idMainElement).offsetTop + 100);
    const callback = (response.statusCode === 200) ? successCallback : errorCallback;

    callback(true);
    setTimeout(() => {
      callback(false);
    }, 10000);

    return response;
  },
};

export default api;