import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap/dist/js/bootstrap';
import CartDetail from '../../cart/partials/CartDetail';
import { Link } from 'react-router-dom';

const OrderDetail = ({ localOrder }) => {

  return (
    <div className="card card-custom py-4">
      <div className="card-custom-heading" id="accordionHeading1">
        <h6 className="card-custom-title heading-decorated">
          <a className="collapsed" role="button" data-toggle="collapse" data-target="#accordionCollapse1"
            href="#accordionCollapse1" aria-controls="accordionCollapse1" aria-expanded="false"
          >Productos añadidos</a>
        </h6>
      </div>
      <div className="card-custom-collapse collapse" id="accordionCollapse1"
        aria-labelledby="accordionHeading1" data-parent="#accordion">
        <div className="card-custom-body">
          <p>
            <strong>
              Si desea modificar su pedido, puede hacerlo desde el <Link to="cart">[carrito]</Link>:
            </strong>
          </p>
          <CartDetail localOrder={localOrder} confirm />
        </div>
      </div>
    </div>
  );

};

OrderDetail.propTypes = {
  localOrder: PropTypes.object.isRequired
};

OrderDetail.defaultProps = {
  localOrder: null
};

export default OrderDetail;