import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { formatPrice, constants } from '../../../utils/common';
import { getUser } from '../../../utils/apiLocal';
import { withRouter } from 'react-router-dom';
import environments from '../../../environments';

const CartResume = ({ localOrder, confirm, allowToContinue, history, payData }) => {

  const shippingCosts = 0;

  const handleOnClickSendOrder = () => {
    const userSession = getUser();

    if (userSession !== null) {
      if (payData) {
        $('#formSendOrderToPay').submit();
      } else {
        history.push({
          pathname: environments.BASE_PATH.concat('orderConfirm'),
        });
      }
    } else {
      history.push({
        pathname: environments.BASE_PATH.concat('login'),
        params: { redirect: 'orderConfirm' }
      });
    }
  };

  return (
    <>
      <ul className="list-sm contact-info">
        <li>
          <dl className="list-terms-inline row">
            <dt className="col-md-8">Subtotal (IVA incl.)</dt>
            <dd className="col-md-4 text-right">
              {formatPrice(localOrder.items.reduce((a, b) =>
                a + ((b.hanger ? b.productItem.unitPrice + constants.HANGER_UNIT_PRICE : b.productItem.unitPrice) * b.numberUnits), 0))}
            </dd>
          </dl>
        </li>
        <li>
          <dl className="list-terms-inline row">
            <p className="col-md-12">Gastos de envío gratuitos</p>
            {/*<dd className="col-md-4 text-right">{formatPrice(shippingCosts)}</dd>*/}
          </dl>
        </li>
      </ul>
      <div className="row border-bottom border-top py-3">
        <div className="col-md-8">
          <p className="blurb__title heading-6">Total</p>
        </div>
        <div className="col-md-4 text-right">
          <p className="blurb__title heading-6">
            {formatPrice(localOrder.items.reduce((a, b) =>
              a + ((b.hanger ? b.productItem.unitPrice + constants.HANGER_UNIT_PRICE : b.productItem.unitPrice) * b.numberUnits), 0) + shippingCosts)}
          </p>
        </div>
      </div>
      {localOrder.items.length > 0 && allowToContinue && (
        <div className="row">
          <button type="button" className="button button-black button-shadow col-md-12"
            onClick={handleOnClickSendOrder}>{payData !== null ? 'Pagar pedido' : confirm ? 'Confirmar pedido' : 'Realizar pedido'}</button>
        </div>
      )}
      {payData !== null && (
        <form id="formSendOrderToPay" name="frm" action={environments.URL_REDSYS} method="POST">
          <input type="hidden" name="Ds_SignatureVersion" value={payData.version} />
          <input type="hidden" name="Ds_MerchantParameters" value={payData.params} />
          <input type="hidden" name="Ds_Signature" value={payData.signature} />
        </form>
      )}
    </>
  );

};

CartResume.propTypes = {
  localOrder: PropTypes.object.isRequired,
  confirm: PropTypes.bool,
  payData: PropTypes.object,
  allowToContinue: PropTypes.bool
};

CartResume.defaultProps = {
  localOrder: null,
  confirm: false,
  payData: null,
  allowToContinue: true
};

export default withRouter(CartResume);