import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

function SearchOrders({ onSearch }) {

  const statuses = [
    {
      value: 'SENDED',
      label: 'Enviado'
    },
    {
      value: 'DELIVERED',
      label: 'Entregado'
    },
    {
      value: 'ORDERED',
      label: 'Pagado'
    },
    {
      value: 'IN_PROGRESS',
      label: 'En Proceso'
    }
  ];

  const formik = useFormik({
    initialValues: {
      userId: '',
      status: '',
      redsysId: '',
    },
    onSubmit: onSearch
  });

  return (
    <div className="col-md-12">
      <form id="searchOrders" className="rd-mailform rd-mailform_style-1 form-shadow row" onSubmit={formik.handleSubmit}>
        <div className="col-md-4">
          <div className="form-wrap form-wrap_icon linear-icon-man">
            <input className="form-input" id="userId" type="text" name="userId" onChange={formik.handleChange}
              onBlur={formik.handleBlur} value={formik.values.userId} />
            {formik.errors.userId ? <span className="form-validation">{formik.errors.userId}</span> : null}
            <label className="form-label" htmlFor="userId">Apellidos Cliente</label>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-wrap form-wrap_icon">
            <select className="custom-select" id="status" name="status" value={formik.values.status} onBlur={formik.handleBlur}
              onChange={formik.handleChange}>
              <option value="" label="Todos" />
              {statuses.map((status, index) => (
                <option key={index} value={status.value} label={status.label} />
              ))}
            </select>
            {formik.errors.status ? <span className="form-validation">{formik.errors.status}</span> : null}
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-wrap form-wrap_icon linear-icon-database-history">
            <input className="form-input" id="redsysId" type="text" name="redsysId" onChange={formik.handleChange}
              onBlur={formik.handleBlur} value={formik.values.redsysId} />
            {formik.errors.redsysId ? <span className="form-validation">{formik.errors.redsysId}</span> : null}
            <label className="form-label" htmlFor="redsysId">Id. TPV</label>
          </div>
        </div>
        <div className="col-md-12 text-right mt-2">
          <button className="button button-black button-shadow" type="submit">Buscar</button>
        </div>
      </form>
    </div>
  );
}

SearchOrders.propTypes = {
  onSearch: PropTypes.func.isRequired
};

SearchOrders.defaultProps = {};

export default SearchOrders;