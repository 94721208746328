import React from 'react';
import loadjs from 'loadjs';
import env from '../../environments';
import Header from '../../commons/Header';
import PreFooter from '../../commons/PreFooter';
import Footer from '../../commons/Footer';
import TermsAndConditionsTitle from './partials/TermsAndConditionsTitle';
import TermsAndConditionsDetail from './partials/TermsAndConditionsDetail';

class TermsAndConditions extends React.Component {

  componentDidMount() {
    loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
      window.scrollTo(0, 0);
    });
  }

  render() {
    return (
      <div className="page">
        <Header />
        <TermsAndConditionsTitle />
        <TermsAndConditionsDetail />
        <PreFooter />
        <Footer />
      </div>
    );
  }
}

export default TermsAndConditions;