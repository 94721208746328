import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { getUser, setUser } from '../../../utils/apiLocal';
import api from '../../../utils/api';

function PersonalDataForm() {

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [towns, setTowns] = useState([]);

  const userSession = getUser();

  const reloadProvinces = () => {
    api.get('users/provinces')
      .then(response => {
        if (response.statusCode === 200) {
          setProvinces(response.data);
        }
      });
  };

  const reloadTowns = (idProvince) => {
    api.get(`users/towns/${idProvince}`)
      .then(response => {
        if (response.statusCode === 200) {
          setTowns(response.data.map(town => {
            return {
              id: town.id,
              postalCode: town.postalCode,
              town: town.town.replace('&Aacute;', 'Á').replace('&Eacute;', 'É')
                .replace('&Iacute;', 'Í').replace('&Oacute;', 'Ó').replace('&Uacute;', 'Ú')
                .replace('&Ntilde;', 'Ñ')
            };
          }));
        }
      });
  };

  useEffect(() => {
    reloadProvinces();
    reloadTowns(userSession.town.province.id);
  }, []);

  const validate = values => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = 'Tu nombre es obligatorio';
    } else if (values.firstName.length < 3 || values.firstName.length > 50) {
      errors.firstName = 'Debe tener entre 3  y 50';
    }

    if (!values.lastName) {
      errors.lastName = 'Tus apellidos son obligatorios';
    } else if (values.lastName.length < 3 || values.lastName.length > 100) {
      errors.lastName = 'Deben tener entre 3  y 100';
    }

    if (!values.postalAddress) {
      errors.postalAddress = 'Tu dirección es obligatoria';
    } else if (values.postalAddress.length < 5 || values.postalAddress.length > 100) {
      errors.postalAddress = 'Deben tener entre 5  y 100';
    }

    if (!values.province) {
      errors.province = 'Tu provincia es obligatoria';
    }

    if (!values.townId) {
      errors.townId = 'Tu población es obligatoria';
    }

    if (!values.telephoneContact) {
      errors.telephoneContact = 'Tu teléfono es obligatorio';
    } else if (values.telephoneContact.length !== 9) {
      errors.telephoneContact = 'Debe tener 9 dígitos sin espacios';
    }

    if (!values.email) {
      errors.email = 'Tu correo electrónico es obligatorio';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Correo electrónico no válido';
    }

    return errors;
  };

  const handleOnSubmit = (values) => {
    values.townId = parseInt(values.townId);

    api.post('users/personalData', values)
      .then(response => {
        window.scrollTo(0, document.getElementById('PersonalDataForm').offsetTop + 100);

        if (response.statusCode === 200) {
          setErrorText('');
          setUser(response.data.user);
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 10000);
        } else {
          setErrorText(response.error.description);
          setShowErrorAlert(true);
          setTimeout(() => {
            setShowErrorAlert(false);
          }, 10000);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      firstName: userSession.firstName,
      lastName: userSession.lastName,
      postalAddress: userSession.postalAddress,
      province: userSession.town.province.id,
      townId: userSession.town.id,
      postalCode: userSession.town.postalCode,
      telephoneContact: userSession.telephoneContact,
      email: userSession.email
    },
    validate,
    onSubmit: handleOnSubmit
  });

  const handleOnChangeTown = (e) => {
    formik.setFieldValue('townId', e.target.value);
    formik.setFieldValue('postalCode', towns.filter(town => town.id === parseInt(e.target.value))[0].postalCode);
  };

  const handleOnChangeProvince = (e) => {
    formik.setFieldValue('province', e.target.value);

    reloadTowns(e.target.value);
  };

  return (
    <>
      <h6 className="heading-decorated">Datos personales</h6>
      <form id="PersonalDataForm" className="rd-mailform rd-mailform_style-1 form-shadow" onSubmit={formik.handleSubmit}>
        {showSuccessAlert && <div className="alert alert-success fade show" role="alert">
          <strong>OK!</strong> Tus datos personales han sido modificados correctamente.
        </div>}
        {showErrorAlert && <div className="alert alert-danger fade show" role="alert">
          <strong>ERROR!</strong> No se han podido modificar tus datos personal. Por favor, revisa esto:<br />{errorText}
        </div>}
        <div className="form-wrap form-wrap_icon linear-icon-man">
          <input className="form-input" id="firstName" type="text" name="firstName" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.firstName} />
          {formik.errors.firstName ? <span className="form-validation">{formik.errors.firstName}</span> : null}
          <label className="form-label" htmlFor="firstName">Nombre</label>
        </div>
        <div className="form-wrap form-wrap_icon linear-icon-man2">
          <input className="form-input" id="lastName" type="text" name="lastName" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.lastName} />
          {formik.errors.lastName ? <span className="form-validation">{formik.errors.lastName}</span> : null}
          <label className="form-label" htmlFor="lastName">Apellidos</label>
        </div>
        <div className="form-wrap form-wrap_icon linear-icon-home">
          <input className="form-input" id="postalAddress" type="text" name="postalAddress" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.postalAddress} />
          {formik.errors.postalAddress ? <span className="form-validation">{formik.errors.postalAddress}</span> : null}
          <label className="form-label" htmlFor="postalAddress">Dirección</label>
        </div>
        <div className="form-wrap form-wrap_icon">
          <select className="custom-select" id="province" name="province" value={formik.values.province} onChange={handleOnChangeProvince}
            onBlur={formik.handleBlur}>
            <option value="" label="Selecciona tu provincia" disabled />
            {provinces.map((prov, index) => (
              <option key={index} value={prov.id} label={prov.province} />
            ))}
          </select>
          {formik.errors.province ? <span className="form-validation">{formik.errors.province}</span> : null}
        </div>
        <div className="form-wrap form-wrap_icon">
          <select className="custom-select" id="townId" name="townId" value={formik.values.townId} onChange={handleOnChangeTown}
            onBlur={formik.handleBlur}>
            <option value="" label="Selecciona tu población" disabled />
            {towns.map((town, index) => (
              <option key={index} value={town.id} label={town.town.concat(' - ').concat(town.postalCode)} />
            ))}
          </select>
          {formik.errors.townId ? <span className="form-validation">{formik.errors.townId}</span> : null}
        </div>
        <div className="form-wrap form-wrap_icon linear-icon-city">
          <input className="form-input" id="postalCode" type="text" name="postalCode" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.postalCode} disabled />
          {formik.errors.postalCode ? <span className="form-validation">{formik.errors.postalCode}</span> : null}
          <label className="form-label" htmlFor="postalCode">Código postal</label>
        </div>
        <div className="form-wrap form-wrap_icon linear-icon-phone-wave">
          <input className="form-input" id="telephoneContact" type="text" name="telephoneContact" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.telephoneContact} />
          {formik.errors.telephoneContact ? <span className="form-validation">{formik.errors.telephoneContact}</span> : null}
          <label className="form-label" htmlFor="telephoneContact">Teléfono</label>
        </div>
        <div className="form-wrap form-wrap_icon linear-icon-envelope">
          <input className="form-input" id="email" type="email" name="email" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.email} />
          {formik.errors.email ? <span className="form-validation">{formik.errors.email}</span> : null}
          <label className="form-label" htmlFor="email">Email</label>
        </div>
        <button className="button button-black button-shadow" type="submit">Guardar</button>
      </form>
    </>
  );
}

export default PersonalDataForm;