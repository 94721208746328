import React from 'react';
import { Link } from 'react-router-dom';
import env from '../../../environments';

function HomeContact() {

  const image = 'url('.concat(env.BASE_ASSETS_URL).concat('images/bg-image-8.jpg)');

  return (
    <section id="contact" className="section-md bg-accent bg-image text-center bg-overlay-darker"
      style={{ backgroundImage: image, backgroundPosition: 'center 50%' }}>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-11 col-lg-9 col-xl-8">
            <h3 className="heading-decorated">¿No has encontrado lo que buscas? Por favor, preguntanos</h3>
            <Link className="button button-black button-shadow" to={env.BASE_PATH.concat('contact')}
              title="Ponte en contacto con nosotros o revisa nuestra situación">Contactanos</Link>
          </div>
        </div>
      </div>
      <div className="bg-slogan-2">
        <img src={env.BASE_ASSETS_URL.concat('images/home/line1.png')} alt="" />
      </div>
    </section>
  );
}

export default HomeContact;