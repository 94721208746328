import React from 'react';
import loadjs from 'loadjs';
import env from '../../environments';
import Header from '../../commons/Header';
import PreFooter from '../../commons/PreFooter';
import Footer from '../../commons/Footer';
import ContactTitle from './partials/ContactTitle';
import ContactDetails from './partials/ContactDetails';
import ContactForm from './partials/ContactForm';
import GoogleMaps from '../../commons/GoogleMaps';

class Contact extends React.Component {

  componentDidMount() {
    loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
      window.scrollTo(0, 0);
    });
  }

  render() {
    return (
      <div className="page">
        <Header isContact />
        <ContactTitle />

        <section className="section-xs bg-default text-md-left decor-text" data-content="Contacto">
          <div className="container">
            <div className="row row-50">
              <ContactDetails />
              <ContactForm />
            </div>
          </div>
        </section>
        {/* <section>
          <GoogleMaps />
        </section> */}
        <PreFooter isContact />
        <Footer />
      </div>
    );
  }
}

export default Contact;