import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap';
import { formatPrice, constants } from '../../../utils/common';

const CartDetail = ({ localOrder, onRefresh, confirm }) => {

  const [order, setOrder] = useState([]);

  useEffect(() => {
    setOrder(localOrder);
  }, [localOrder]);

  const handleOnchangeNumberItems = (index, event) => {
    const currentOrder = order;

    let parsedValue = event.target.value || '1';
    parsedValue = parsedValue.replace(/^0+/, '').replace('.', '').replace('-', '');

    currentOrder.items[index].numberUnits = parseInt(parsedValue || 1);

    setOrder(currentOrder);
    onRefresh(currentOrder);
  };

  const handleOnClickDelete = (index) => {
    $('#confirmDeleteModal').modal('toggle');
    $('#confirmDeleteYes').click(e => {
      const currentOrder = order;

      currentOrder.items.splice(index, 1);

      setOrder(currentOrder);
      onRefresh(currentOrder);
      $('#confirmDeleteModal').modal('toggle');
    });
  };

  return (
    <>
      <ul className="list-sm mt-5">
        {order.items && order.items.length === 0 && (
          <div className="alert alert-danger fade show" role="alert">
            <strong>Importante</strong> No tienes ningún producto añadido a tu carrito.
          </div>
        )}
        {order.items && order.items.map((item, index) => (
          <li key={index} className="border-bottom pb-3">
            <article className="post-inline">
              <div className="row">
                <div className="col-md-2">
                  <a download={`Descarga ${order.id}_${index+1}`} href={item.image} className="img-thumbnail-variant-3"
                    data-lightgallery="item" title="Descargar imagen">
                    <img src={item.image} className="img-fluid mx-auto d-block"
                      alt="Previsualización de tu imagen seleccionada" />
                  </a>
                </div>
                <div className="col-md-7">
                  <p className="blurb__title heading-6">{item.name}</p>
                  <div className="post-inline__header">
                    <span className="post-inline__time">
                      Tamaño: {item.productItem.title}
                    </span>
                    {!confirm && (
                      <a className="post-inline__author button-link" href="#this" onClick={() => handleOnClickDelete(index)}>Eliminar</a>
                    )}
                    <br />
                    {item.hanger && (
                      <span className="post-inline__time">Con colgador</span>
                    )}
                  </div>
                  <p className="post-inline__link" dangerouslySetInnerHTML={{ __html: item.description.split("\n")[0].concat(' ...') }}></p>
                </div>
                <div className="col-md-3 text-right">
                  <h6>
                    <code className="px-1 py-1">
                      {formatPrice(item.hanger ? item.productItem.unitPrice + constants.HANGER_UNIT_PRICE : item.productItem.unitPrice)}/Ud.
                    </code>
                  </h6>
                  <div className="row">
                    <div className="col-md-9 offset-md-3">
                      {confirm && (
                        'Unidades: '.concat(item.numberUnits)
                      )}
                      {!confirm && (
                        <div className="form-wrap form-wrap_icon fa-shopping-cart">
                          <input className="form-input text-right" id="numberUnits" type="number" name="numberUnits"
                            value={item.numberUnits} onChange={event => handleOnchangeNumberItems(index, event)}
                            min="1" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </li>
        ))}
      </ul>
      <div className="modal fade" id="confirmDeleteModal" tabIndex="-1" role="dialog"
        aria-labelledby="confirmDeleteModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Eliminar producto de la cesta</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>¿Está seguro de querer eliminar el producto de su carrito?</p>
            </div>
            <div className="modal-footer">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 text-right">
                    <button id="confirmDeleteYes" type="button" className="button button-black button-shadow">Sí</button>
                  </div>
                  <div className="col-md-6">
                    <button id="confirmDeleteNo" type="button" className="button button-black button-shadow" data-dismiss="modal">Cerrar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

CartDetail.propTypes = {
  localOrder: PropTypes.object.isRequired,
  onRefresh: PropTypes.func,
  confirm: PropTypes.bool
};

CartDetail.defaultProps = {
  localOrder: null,
  onRefresh: null,
  confirm: false
};

export default CartDetail;