import React from 'react';
import loadjs from 'loadjs';
import env from '../../../environments';
import Header from '../../../commons/Header';
import Footer from '../../../commons/Footer';
import GridUsers from './partials/GridUsers';
import SearchUsers from './partials/SearchUsers';

class AdminUsers extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.gridUsersRef = null;

    this.handleOnSearch = this.handleOnSearch.bind(this);

    this.userId = this.props.location.params && this.props.location.params.userId ? this.props.location.params.userId : null;
  }

  componentDidMount() {
    loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
      window.scrollTo(0, 0);
    });
  }

  handleOnSearch(formValues) {
    this.gridUsersRef.search(formValues);
  }

  render() {
    return (
      <div className="page">
        <Header isAdmin />
        <section className="section-xs bg-default text-md-left decor-text" data-content="Usuarios">
          <div className="container">
            <div className="row justify-content-sm-center align-items-center">
              <div className="col-12 text-center">
                <h2 className="heading-decorated">Administración de Usuarios</h2>
              </div>
            </div>
            <div className="row justify-content-sm-left">
              <SearchUsers onSearch={this.handleOnSearch} />
              <GridUsers ref={(ref) => this.gridUsersRef = ref} userId={this.userId} />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default AdminUsers;