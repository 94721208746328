import React, { useCallback, useEffect, useState } from 'react';
import $ from 'jquery';
import 'bootstrap-slider/dist/bootstrap-slider';
import 'bootstrap-slider/dist/css/bootstrap-slider.css';
import './style.css';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import getCroppedImg from './utils/getCroppedImg';

const CropImage = ({ image, aspect, onChange }) => {

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  useEffect(() => {
    $('#zoomSlider').slider({
      formatter: function (value) {
        return 'Current value: ' + value;
      }
    }).on('change', function (event) {
      setZoom(event.value.newValue);
    });

    $('#rotationSlider').slider({
      formatter: function (value) {
        return 'Current value: ' + value;
      }
    }).on('change', function (event) {
      setRotation(event.value.newValue);
    });

    showCroppedImage();
  }, [croppedAreaPixels]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      if (croppedAreaPixels !== null) {
        const croppedImage = await getCroppedImg(
          image,
          croppedAreaPixels,
          rotation
        );

        setCroppedImage(croppedImage);
        onChange(croppedImage);
      }
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation, image]);

  const handleOnZoomChange = (zoom) => {
    setZoom(zoom);
    $('#zoomSlider').slider('setValue', zoom);
  };

  return (
    <div className="row justify-content-md-center my-4">
      <div className="col-md-6">
        <div className="crop-container">
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={aspect.width / aspect.height}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={handleOnZoomChange}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-6">
            <p>Zoom</p>
            <input id="zoomSlider" data-slider-id='zoom' type="text" data-slider-min="1"
              data-slider-max="3" data-slider-step="0.1" data-slider-value={zoom} />
          </div>
          <div className="col-md-6">
            <p>Rotación</p>
            <input id="rotationSlider" data-slider-id='rotation' type="text" data-slider-min="0"
              data-slider-max="360" data-slider-step="1" data-slider-value={rotation} />
          </div>
          <div className="col-md-12 mt-3">
            {croppedAreaPixels !== null && croppedAreaPixels.width >= aspect.width && croppedAreaPixels.height >= aspect.height && (
              <div className="alert alert-success fade show text-left" role="alert">
                <strong>El recorte de su foto es de buena calidad:</strong> Puede seguir retocando el zoom y/o la rotación de su
                  foto original subida.
                <span className="icon-md mdi fa-check float-right"></span>
              </div>
            )}
            {croppedAreaPixels !== null && (croppedAreaPixels.width < aspect.width || croppedAreaPixels.height < aspect.height) && (
              <div className="alert alert-danger fade show text-left" role="alert">
                <strong>El recorte de su foto no tiene la calidad suficiente:</strong> Si ha aumentado el zoom de la foto original
                subida, deberá alejarlo hasta conseguir un recorte de mayor calidad.<br />
                Si el zoom está alejado al máximo, deberá subir otra foto de mayor calidad, ya que la actual no es adecuada.
                <span className="icon-md mdi fa-window-close float-right"></span>
              </div>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <img src={croppedImage} className="img-fluid mx-auto d-block"
              alt="Previsualización de tu imagen seleccionada" />
          </div>
          <div className="col-md-6 mt-3">
            <button type="button" className="button button-black button-shadow d-block width-100"
              data-toggle="modal" data-target="#previewModal">Previsualizar</button>
            <button className="button button-primary button-shadow d-block width-100 disabled" type="submit"
              disabled={croppedAreaPixels !== null && (croppedAreaPixels.width < aspect.width || croppedAreaPixels.height < aspect.height)}
            >Comprar</button>
          </div>
        </div>
      </div>
      {croppedImage && (
        <div className="modal fade" id="previewModal" tabIndex="-1" role="dialog"
          aria-labelledby="previewModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Previsualización</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <img src={croppedImage} alt="Imagen previsualizada" />
              </div>
              <div className="modal-footer">
                <button type="button" className="button button-black button-shadow" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

};

CropImage.propTypes = {
  image: PropTypes.string.isRequired,
  aspect: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

CropImage.defaultProps = {
  image: null,
  aspect: null,
  onChange: null
};

export default CropImage;