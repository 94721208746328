import React from 'react';
import env from '../../../environments';

function ProductTitle({ product }) {
  return (
    <section>
      <section className="section parallax-container context-dark"
        data-parallax-img={env.BASE_ASSETS_URL.concat('images/products/'.concat(product.id).concat('-1212x913.jpg'))}>
        <div className="parallax-content parallax-header">
          <div className="parallax-header__inner context-dark">
            <div className="parallax-header__content">
              <div className="container">
                <div className="row justify-content-sm-center">
                  <div className="col-md-10 col-xl-8">
                    <h2 className="heading-decorated">{product.name}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default ProductTitle