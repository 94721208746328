import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import api from '../../../utils/api';
import { getFileFromApiResponse, getBase64Flag } from '../../../utils/common';

function ContactForm() {

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [captcha, setCaptcha] = useState(false);

  useEffect(() => {
    getCaptcha();
  }, []);

  const getCaptcha = () => {
    const fileType = 'image/png';

    api.get('users/captcha/generate', {}, fileType)
      .then(getFileFromApiResponse)
      .then(response => {
        setCaptcha(getBase64Flag(fileType).concat(response));
      });
  };

  const validate = values => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Tu nombre es obligatorio';
    } else if (values.name.length < 3) {
      errors.name = 'Debe tener 3 caracteres o más';
    }


    if (!values.email) {
      errors.email = 'Tu correo electrónico es obligatorio';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Correo electrónico no válido';
    }

    if (!values.message) {
      errors.message = 'Tu mensaje es obligatorio';
    }

    if (!values.captcha) {
      errors.captcha = 'El texto del captcha es obligatorio';
    }

    return errors;
  };

  const handleOnSubmit = (values) => {
    api.post('users/sendContactEmail', values)
      .then(response => {
        window.scrollTo(0, document.getElementById('contactForm').offsetTop + 100);
        getCaptcha();

        if (response.statusCode === 200 || response.statusCode === 500) {
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 10000);
          formik.resetForm();
        } else {
          setShowErrorAlert(true);
          setTimeout(() => {
            setShowErrorAlert(false);
          }, 10000);

          if (response.statusCode === 400) {
            formik.setFieldError('captcha', response.error.description);
          }
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
      captcha: ''
    },
    validate,
    onSubmit: handleOnSubmit
  });

  return (
    <div className="col-md-7 col-lg-8">
      <h6 className="heading-decorated">Ponte en contacto con nosotros</h6>
      <form id="contactForm" className="rd-mailform rd-mailform_style-1 form-shadow" onSubmit={formik.handleSubmit}>
        {showSuccessAlert && <div className="alert alert-success fade show" role="alert">
          <strong>OK!</strong> Tu consulta ha sido enviada correctamente. Nos pondremos en contacto contigo a la
          mayor brevedad posible. Muchas gracias
        </div>}
        {showErrorAlert && <div className="alert alert-danger fade show" role="alert">
          <strong>ERROR!</strong> Se ha producido un error inesperado y no se ha podido enviar tu consulta. Vuelva
          a intentarlo más tarde y perdone las molestias.
        </div>}
        <div className="form-wrap form-wrap_icon linear-icon-man">
          <input className="form-input" id="name" type="text" name="name" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.name} />
          {formik.errors.name ? <span className="form-validation">{formik.errors.name}</span> : null}
          <label className="form-label" htmlFor="name">Tu nombre</label>
        </div>
        <div className="form-wrap form-wrap_icon linear-icon-envelope">
          <input className="form-input" id="email" type="email" name="email" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.email} />
          {formik.errors.email ? <span className="form-validation">{formik.errors.email}</span> : null}
          <label className="form-label" htmlFor="email">Tu correo electrónico</label>
        </div>
        <div className="form-wrap form-wrap_icon linear-icon-feather">
          <textarea className="form-input" id="message" name="message" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.message} maxLength={1000} />
          {formik.errors.message ? <span className="form-validation">{formik.errors.message}</span> : null}
          <label className="form-label" htmlFor="message">Tu mensaje</label>
        </div>
        <div className="text-center m-4">
          <img src={captcha} alt="Captcha" />
        </div>
        <div className="form-wrap form-wrap_icon linear-icon-pencil4">
          <input className="form-input" id="captcha" type="text" name="captcha" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.captcha} />
          {formik.errors.captcha ? <span className="form-validation">{formik.errors.captcha}</span> : null}
          <label className="form-label" htmlFor="captcha">Por favor, Introduce el texto mostrado en el captcha</label>
        </div>
        <button className="button button-black button-shadow" type="submit">Enviar</button>
      </form>
    </div>
  );
}

export default ContactForm;