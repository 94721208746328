import React from 'react';
import environments from '../../../environments';

function Slogan() {
  return (
    <section className="section-xs section-cta bg-gray-dark text-center text-md-left">
      <div className="container">
        <div className="row row-30 justify-content-between align-items-center">
          <div className="col-12 col-md-8">
            <p><strong>¿Te apetece redecorar tu hogar y estás buscando ideas para personalizar tus paredes?</strong></p>
            <p><strong>KUADRUKIS</strong> te ofrece varios materiales de la mejor calidad para personalizar e imprimir
              tus mejores fotos.</p>
            <h4>Decora tu hogar con tus fotos
              preferidas</h4>
          </div>
          <div className="col-12 col-md-4 text-md-right">
            <a className="button button-black button-shadow" href="#products" data-custom-scroll-to="products"
              title="Ver todos nuestros productos">Crear ahora</a>
          </div>
        </div>
      </div>
      <div className="bg-slogan">
        <img src={environments.BASE_ASSETS_URL.concat('images/home/line1.png')} alt="" />
      </div>
    </section>
  );
}

export default Slogan;