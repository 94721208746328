import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import env from '../environments';
// import data from '../staticData';
// import GoogleMaps from './GoogleMaps';

function PreFooter({ isHome, isContact }) {

  let activeHomeMenuClassName = '';
  // let colsDistributionClassName = [
  //   'col-sm-10 col-md-6 col-lg-10 col-xl-4',
  //   'col-sm-10 col-md-6 col-lg-6 col-xl-4',
  //   'col-sm-10 col-md-12 col-lg-6 col-xl-4'
  // ];

  if (isHome) {
    activeHomeMenuClassName = 'active';
  }

  return (
    <section className="pre-footer-corporate bg-image-7 bg-overlay-darkest">
      <div className="container">
        <div className="row justify-content-sm-center justify-content-lg-start">
          <div className="col-md-4">
            <ul className="list-xxs">
              <li>
                {activeHomeMenuClassName.length === 0 && <Link to={env.BASE_PATH} title="Ir a la página principal">Inicio</Link>}
                {activeHomeMenuClassName.length > 0 && <a href="#home" title="Ir a la página principal"
                  data-custom-scroll-to="home">Inicio</a>}
              </li>
              <li>
                {activeHomeMenuClassName.length === 0 && <Link to={env.BASE_PATH.concat('#products')} title="Ver todos nuestros productos">
                  Nuestros productos</Link>}
                {activeHomeMenuClassName.length > 0 && <a href="#products" title="Ver todos nuestros productos"
                  data-custom-scroll-to="products">Nuestros productos</a>}
              </li>
              <li>
                {activeHomeMenuClassName.length === 0 && <Link to={env.BASE_PATH.concat('#about')} title="Ver más información sobre quienes somos">
                  Quienes Somos</Link>}
                {activeHomeMenuClassName.length > 0 && <a href="#about" title="Ver más información sobre quienes somos"
                  data-custom-scroll-to="about">Quienes Somos</a>}
              </li>
              <li>
                <Link to={env.BASE_PATH.concat('shipmentsAndReturns')} title="Información de envíos y devoluciones">Envíos y Devoluciones</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul className="list-xxs">
              <li>
                <Link to={env.BASE_PATH.concat('contact')} title="Ponte en contacto con nosotros o revisa nuestra situación">Contacto y Situación</Link>
              </li>
              <li>
                {activeHomeMenuClassName.length === 0 && <Link to={env.BASE_PATH.concat('#inspiration')} title="Ver ideas para inspirarte">
                  Ideas para inspirarte</Link>}
                {activeHomeMenuClassName.length > 0 && <a href="#inspiration" title="Ver ideas para inspirarte"
                  data-custom-scroll-to="inspiration">Ideas para inspirarte</a>}
              </li>
              <li>
                <Link to={env.BASE_PATH.concat('cart')} title="Ver mi carrito de la compra actual">Carrito</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul className="list-xxs">
              <li>
                <Link to={env.BASE_PATH.concat('privacity')} title="Ver toda la información de nuestra política de privacidad">Política de Privacidad</Link>
              </li>
              <li>
                <Link to={env.BASE_PATH.concat('cookies')} title="Ver toda la información de nuestra política de cookies">Política de Cookies</Link>
              </li>
              <li>
                <Link to={env.BASE_PATH.concat('termsAndConditions')} title="Ver toda la información de nuestros términos y condiciones">Términos y Condiciones</Link>
              </li>
            </ul>
          </div>
          {/* <div className={colsDistributionClassName[0]}>
            <h6>Sobre nosotros</h6>
            <p><strong>KUADRUKIS</strong> es una empresa formada por un grupo de jovenes inquietos, emprendedores y con
              iniciativa que decidieron dar respuesta a un servicio muy demandando.</p>
          </div>
          <div className={colsDistributionClassName[1]}>


          </div>
          <div className={colsDistributionClassName[2]}>
            <h6>Contacto</h6>
            <ul className="list-xs">
              <li>
                <dl className="list-terms-minimal">
                  <dt>Dirección</dt>
                  <dd>
                    <a target="_blank" rel="noopener noreferrer" title={data.address.title}
                      href={data.address.url}>{data.address.name}</a>
                  </dd>
                </dl>
              </li>
              <li>
                <dl className="list-terms-minimal">
                  <dt>Teléfonos</dt>
                  <dd>
                    <ul className="list-semicolon">
                      <li>
                        <a href={data.phone.url} title={data.phone.title}>{data.phone.name}</a>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </li>
              <li>
                <dl className="list-terms-minimal">
                  <dt>E-mail</dt>
                  <dd>
                    <ul>
                      <li>
                        <a href="mailto:info@ingenia.ink"
                          title="Enviar correo electrónico a esta dirección">info@ingenia.ink</a>
                      </li>
                      <li>
                        | <a href="mailto:pedidos@ingenia.ink"
                          title="Enviar correo electrónico a esta dirección">pedidos@ingenia.ink</a>
                      </li>
                      <li>
                        | <a href="mailto:comercial@ingenia.ink"
                          title="Enviar correo electrónico a esta dirección">comercial@ingenia.ink</a>
                      </li>
                      <li>
                        | <a href="mailto:impresion@ingenia.ink"
                          title="Enviar correo electrónico a esta dirección">impresion@ingenia.ink</a>
                      </li>
                      <li>
                        | <a href="mailto:creativo@ingenia.ink"
                          title="Enviar correo electrónico a esta dirección">creativo@ingenia.ink</a>
                      </li>
                      <li>
                        | <a href="mailto:webmaster@ingenia.ink"
                          title="Enviar correo electrónico a esta dirección">webmaster@ingenia.ink</a>
                      </li>
                      <li>
                        | <a href="mailto:administracion@ingenia.ink"
                          title="Enviar correo electrónico a esta dirección">administracion@ingenia.ink</a>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </li>
              <li>
                <dl className="list-terms-minimal">
                  <dt>Horario de apertura</dt>
                  <dd>{data.openingHours}</dd>
                </dl>
              </li>
            </ul>
          </div>
          {!isContact && <div className={colsDistributionClassName[3]}>
            <GoogleMaps fromFooter />
          </div>} */}
        </div>
      </div>
    </section>
  );
}

PreFooter.propTypes = {
  isHome: PropTypes.bool.isRequired,
  isContact: PropTypes.bool.isRequired,
};

PreFooter.defaultProps = {
  isHome: false,
  isContact: false,
};

export default PreFooter;