const environments = [
  {
    name: 'development',
    baseLocations: ['http://localhost:3000', 'http://dummy-host.ingeniato.com:3000'],
    // API_URL: 'http://dummy-host.ingeniato.com:8080/tiendaOnline/api/',
    API_URL: 'http://localhost:8080/ingenia-backend/api/',
    BASE_PATH: '/',
    BASE_ASSETS_URL: '/assets/',
    URL_REDSYS: 'https://sis-t.redsys.es:25443/sis/realizarPago'
  },
  {
    name: 'production',
    baseLocations: ['https://www.kuadrukis.com', 'https://kuadrukis.com', 'http://kuadrukis.com', 'http://www.kuadrukis.com'],
    API_URL: null,
    BASE_PATH: '/',
    BASE_ASSETS_URL: '/assets/',
    URL_REDSYS: 'https://sis.redsys.es/sis/realizarPago'
  }
];

const getEnvironment = () => {
  const result = environments.filter(env => {
    return env.baseLocations.indexOf(document.location.origin) !== -1
  });

  const envToUse = result[0];

  if (envToUse.name === 'production') {
    const baseLocationIndex = envToUse.baseLocations.indexOf(document.location.origin);
    envToUse.API_URL = envToUse.baseLocations[baseLocationIndex].concat('/api/');
  }

  return envToUse;
}

export default getEnvironment();