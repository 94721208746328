import React, { useState, useEffect } from 'react';
import api from '../../../../utils/api';
import { mapItemsToOrderLines, getOrderTitle } from '../../../../utils/common';
import CartDetail from '../../../cart/partials/CartDetail';

const ModalOrderDetail = props => {

  const [data, setData] = useState(null);

  const reloadOrderData = () => {
    if (props.orderId !== null) {
      api.get(`orders/${props.orderId}`)
        .then(response => {
          if (response.statusCode === 200) {
            response.data.items = response.data.orderLines.map(mapItemsToOrderLines);

            setData(response.data);
          } else {
            setData(null);
          }
        });
    }
  };

  useEffect(() => {
    reloadOrderData();
  }, [props.orderId]);

  return (
    <div className="modal fade" id="orderDetailModal" tabIndex="-1" role="dialog"
      aria-labelledby="orderDetailModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Detalle del pedido</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {data === null && (
              <div className="alert alert-danger fade show" role="alert">
                No se ha encontrado información del pedido solicitado.
              </div>
            )}
            {data !== null && (
              <div className="card card-custom py-4">
                <h6 className="card-custom-title">{getOrderTitle(data)}</h6>
                <div className="card-custom-body">
                  <CartDetail localOrder={data} confirm />
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="button button-black button-shadow" data-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalOrderDetail;