import React from 'react';

function PrivacityDetail() {
  return (
    <section className="section-md bg-gray-lighter decor-text" data-content="Privacity">
      <div className="container">
        <div className="row justify-content-lg-center">
          <div className="col-xs-12 text-center">
            <h4 className="heading-decorated">Detalles de nuestra política de privacidad</h4>
          </div>
        </div>
        <div className="row justify-content-lg-left">
          <div className="col-xs-12">
            <p>De conformidad con lo dispuesto en la Ley Orgánica 15/1999, de Protección
            de Datos de Carácter Personal (LOPD) y su normativa de Desarrollo, el responsable de KUADRUKIS.COM,
            en Cumplimiento De lo dispuesto en el art. 5 y 6 de la LOPD, informa a todos los Usuarios de
            KUADRUKIS.COM Que faciliten o vayan a facilitar SUS Datos Personales, Que ESTOS SERÁN incorporados
            miembro En un fichero Automatizado Que SE Encuentra debidamente inscrito en la Agencia Española de
                  Protección de Datos.</p>
            <p>Los Usuarios, MEDIANTE La marcación de la Casilla, aceptan expresamente y De Forma Libre e
            inequívoca Que SUS Datos Personales Sean TRATADOS POR Parte del prestador para Realizar las
                  Siguientes finalidades:</p>
            <p>Remisión de Comunicaciones Comerciales publicitarias por e-mail, fax, SMS, MMS, Comunidades
            sociales o cualesquier Otro medio electrónico o físico, o Futuro Presente, Que posibilite Realizar
            Comunicación Comerciales. Dichas Comunicaciones Comerciales Serán relacionadas Sobre Productos o
            Servicios ofrecidos por KUADRUKIS.COM, Asi Como por parte de los Colaboradores o parthners con Los
            Que Hubiera Este Alcanzado ALGÚN Acuerdo de Promoción Comercial Entre SUS Clientes. En Este Caso,
            Los Terceros Nunca tendrán acceso a los Datos Personales. En TODO Caso las Comunicaciones
            Comerciales Serán Realizadas por parte de KUADRUKIS.COM y Serán de Productos y Servicios
                    Relacionados con el sector de KUADRUKIS.COM.</p>
            <p>Realizar Estudios estadísticos.</p>
            <p>Tramitar encargos, Solicitudes O CUALQUIER tipo de PETICIÓN QUE SEA Realizada por el usuario a
            Través de CUALQUIERA de las Formas de contacto Que se Ponen una Disposición del Usuario en el
                    Sitio Web de la Compañía.</p>
            <p>Remitir el Boletín de Noticias de KUADRUKIS.COM.</p>
            <p>Informa KUADRUKIS.COM y Garantiza expresamente a Los Usuarios Que SUS Datos Personales no serán
            cedidos en ningún Caso de un Terceras Compañías, Y Que Siempre Que Fuera realizarse un ALGÚN tipo
            de cesión de Datos Personales, de forma previa, se solicitaría el Consentimiento expreso,
                    Informado , e inequívoco POR Parte de los Titulares.</p>
            <p>Todos Los Datos Solicitados un Través de KUADRUKIS.COM Obligatorios, ya Que Son Necesarios Para
            La prestación de servicios óptimo al usuario. En Caso de Que No Sean facilitados todos los Datos,
            KUADRUKIS.COM sin Garantiza Que la Información y Servicios facilitados sean Completamente
                    ajustados a sus necesidades.</p>
            <p>Garantiza KUADRUKIS.COM en TODO Caso al usuario el Ejercicio de los Derechos de acceso,
            rectificación, cancelación, Información y Oposición, en los Términos Dispuestos en la Legislación
            Vigente. : Por ello, de conformidad con lo dispuesto en la Ley Orgánica 15/1999, de Protección de
            Datos de Carácter Personal (LOPD) podrá ejercer SUS Derechos remitiendo Una Solicitud Expresa,
                    Junto a Una copia de su DNI, un Través de los Medios SIGUIENTES:</p>
            <p>E-Mail:
                    <a href="mailto:info@ingenia.ink" title="Enviar correo electrónico a esta dirección">info@ingenia.ink</a></p>
            <p>Del Mismo Modo, el usuario podrá darse de baja de CUALQUIERA de los Servicios de Suscripción
            facilitados Haciendo click en el Apartado darse de baja de todos los correos Electrónicos remitidos
                    por parte de KUADRUKIS.COM.</p>
            <p>Del Mismo Modo,  KUADRUKIS.COM ha adoptado TODAS LAS Medidas Técnicas y de Organización necesarias
            para Garantizar la Seguridad e integridad de los Datos de Carácter  personal, Así Como para Evitar
                    su Perdida, Alteración y / o acceso por parte de Terceros Autorizados no hay.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacityDetail;