import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import loadjs from 'loadjs';
import env from '../../../environments';
import api from '../../../utils/api';
import { Link } from 'react-router-dom';

function Products({ location }) {

  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = () => {
    api.get('products')
      .then(response => {
        if (response.statusCode === 200) {
          setProducts(response.data);
          loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
            const hash = location.hash.replace('#', '');
            if (hash.length > 0) {
              setTimeout(() => {
                window.scrollTo(0, document.getElementById(hash).offsetTop);
              }, 1000);
            } else {
              window.scrollTo(0, 0);
            }
          });
        }
      });
  };

  return (
    <section id="products" className="section-md bg-default text-center">
      {/* <div className="bg-decor d-flex align-items-center"
        data-parallax-scroll="{&quot;y&quot;: 50,  &quot;smoothness&quot;: 30}">
        <img src={env.BASE_ASSETS_URL.concat('images/bg-decor-6.png')} alt="" />
      </div> */}
      <div className="bg-about">
        <img src={env.BASE_ASSETS_URL.concat('images/home/square2.png')} alt="" />
      </div>
      <div className="bg-products">
        <img src={env.BASE_ASSETS_URL.concat('images/home/square1.png')} alt="" />
      </div>
      <div className="container">
        <h4 className="heading-decorated">Nuestros productos</h4>
        <div className="isotope-wrap row row-70">
          <div className="col-sm-12">
            <div className="row row justify-content-end">
              <div className="col-sm-9 text-right">
                <h6>TODO EMPIEZA CON UNA FOTO...Un recuerdo especial que deseas que permanezca</h6>
                <p><strong>KUADRUKIS</strong> ofrece una variedad de materiales y medidas disponibles para hacerlo
                  realidad.
                  ¡Sólo tienes que elegir la tuya! Nunca ha sido tan fácil crear tu propio producto. Selecciona tus
                  mejores fotos (PC, Facebook, Instagram ...), añádelas a tu cuenta y elige el tamaño y el material que
                  desees.
                  Cuando hayas finalizado tu producto, haz clic en "Pedido" y nosotros nos encargamos del resto.</p>
              </div>
            </div>
            <div className="isotope row" data-isotope-layout="fitRows" data-isotope-group="gallery">
              {products.map((product, index) => (
                <div key={index} className={`col-12 col-md-6 col-lg-4 ${index == 3 ? 'offset-lg-1' : index == 4 ? 'offset-lg-2' : ''} isotope-item`} data-filter="Category 2">
                  <h5 className="text-subtitle">{product.name}</h5>
                  <Link className="img-thumbnail-variant-3" to={{
                    pathname: env.BASE_PATH.concat('product/'),
                    params: {
                      product
                    }
                  }} title="Ver este producto en detalle">
                    <img src={env.BASE_ASSETS_URL.concat('images/products/').concat(product.id).concat('-418x315.jpg')} alt={product.name} width="418"
                      height="315" />
                    <div className="caption">
                      <span className="icon hover-top-element linear-icon-picture"></span>
                      <ul className="list-inline-tag hover-top-element">
                        {product.productItems.map((item, index2) => (
                          <li key={index2}>&nbsp;{item.title}</li>
                        ))}
                      </ul>
                      <p className="heading-5 hover-top-element">{product.name}</p>
                      <div className="divider"></div>
                      <p className="small hover-bottom-element">Comprar</p>
                      <span className="icon arrow-right linear-icon-plus"></span>
                    </div>
                  </Link>
                </div>
              ))}
              <div className="col-lg-1"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Products.propTypes = {
  location: PropTypes.object.isRequired,
};

Products.defaultProps = {
  location: {},
};

export default Products;