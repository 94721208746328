import React from 'react';
import env from '../../../environments';

function PrintingExperts() {
  return (
    <section className="section-md bg-gray-lighter text-center decor-text" data-content="Impresión">
      <div className="bg-experts">
        <img src={env.BASE_ASSETS_URL.concat('images/home/square1.png')} alt="" />
      </div>
      <div className="container">
        <h4 className="heading-decorated">Somos expertos en impresión digital</h4>
        <div className="row row-30 justify-content-center">
          <div className="col-sm-10 col-lg-6">
            {/* <video className="yourvideo" width="100%" height="100%" controls>
              <source src={env.BASE_ASSETS_URL.concat('images/INGENIA_montaje_de_escenarios.mp4')} type="video/mp4" />
              Tu navegador no soporta vídeos
            </video> */}
            {/* <source src="https://www.youtube.com/watch?v=wgMOrFhbgnM" type="video/mp4" /> */}
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/wgMOrFhbgnM"></iframe>
          </div>
          <div className="col-sm-10 col-lg-6">
            <article className="blurb blurb-circle">
              <div className="unit flex-row unit-spacing-md">
                <div className="unit-left">
                  <div className="blurb-circle__icon"><span className="icon linear-icon-pencil-ruler2"></span></div>
                </div>
                <div className="unit-body">
                  <p className="blurb__title heading-6">Creación de diseño</p>
                  <p>Utilizamos la más última e innovadora tecnología en impresión digital para ofrecerte los mejores
                    resultados en todos y cada uno de los soportes que fabricamos.</p>
                </div>
              </div>
            </article>
            <article className="blurb blurb-circle">
              <div className="unit flex-row unit-spacing-md">
                <div className="unit-left">
                  <div className="blurb-circle__icon"><span className="icon linear-icon-plus"></span></div>
                </div>
                <div className="unit-body">
                  <p className="blurb__title heading-6">Experiencia</p>
                  <p>Nos avala la experiencia de más de 10 años y nuestra ilusión en cada uno de los proyectos que
                    ponemos en marcha.</p>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrintingExperts;