import React from 'react';
import loadjs from 'loadjs';
import env from '../../environments';
import Header from '../../commons/Header';
import PreFooter from '../../commons/PreFooter';
import Footer from '../../commons/Footer';
import MyAccountTitle from './partials/MyAccountTitle';
import MyAccountSections from './partials/MyAccountSections';
import UserOrdersList from './partials/UserOrdersList';

class MyAccountOrders extends React.Component {

  componentDidMount() {
    loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
      window.scrollTo(0, 0);
    });
  }

  render() {
    return (
      <div className="page">
        <Header />
        <section className="section-xs bg-default text-md-left decor-text" data-content="Pedidos realizados">
          <div className="container">
            <MyAccountTitle />
            <div className="row row-50 justify-content-sm-left">
              <MyAccountSections selected="orders" />
              <div className="col-md-8 col-lg-7">
                <UserOrdersList />
              </div>
            </div>
          </div>
        </section>
        <PreFooter />
        <Footer />
      </div>
    );
  }
}

export default MyAccountOrders;