import React, { useEffect, useState } from 'react';
import api from '../../../utils/api';
import { getOrderTitle, mapItemsToOrderLines } from '../../../utils/common';
import CartDetail from '../../cart/partials/CartDetail';

function UserOrdersList() {

  const [orders, setOrders] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    loadOrders();
  }, []);

  const loadOrders = () => {
    api.get('orders/user')
      .then(response => {
        let errorDescription = '';
        let ordersList = [];

        if (response.statusCode === 200) {
          ordersList = response.data;

        } else if (response.statusCode !== 404) {
          errorDescription = response.error.description;
        }

        setOrders(ordersList.map(order => {
          const newOrder = order;

          newOrder.items = newOrder.orderLines.map(mapItemsToOrderLines);

          return newOrder;
        }));
        setErrorMsg(errorDescription);
      });
  };

  return (
    <>
      <h6 className="heading-decorated mb-3">Pedidos realizados</h6>
      {errorMsg.length > 0 && <div className="alert alert-danger fade show" role="alert">
        <strong>ERROR!</strong> No se ha podido obtener el listado de tus pedidos realizados. Por favor, revisa esto:<br />{errorMsg}
      </div>}
      {orders.length === 0 && (
        <div className="alert alert-danger fade show" role="alert">
          <strong>Importante</strong> No tienes ningún pedido todavía.
        </div>
      )}
      {orders.length > 0 && (
        <div id="accordion">
          {orders.map((o, index) => (
            <div key={index} className="card card-custom py-4">
              <div className="card-custom-heading" id="accordionHeading2">
                <h6 className="card-custom-title">
                  <a className="collapsed" role="button" data-toggle="collapse" data-target={`#accordionOrder${index}`}
                    href={`#accordionOrder${index}`} aria-controls={`accordionOrder${index}`} aria-expanded="false"
                  >{getOrderTitle(o)}</a>
                </h6>
              </div>
              <div className="card-custom-collapse collapse" id={`accordionOrder${index}`}
                aria-labelledby="accordionHeading2" data-parent="#accordion">
                <div className="card-custom-body">
                  <CartDetail localOrder={o} confirm />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default UserOrdersList;