import React from 'react';
import loadjs from 'loadjs';
import env from '../../environments';
import Header from '../../commons/Header';
import PreFooter from '../../commons/PreFooter';
import Footer from '../../commons/Footer';
import { Link } from 'react-router-dom';
import environments from '../../environments';

class OrderConfirmResultKo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
      window.scrollTo(0, 0);
    });
  }

  render() {
    return (
      <div className="page">
        <Header />
        <section className="section-xs bg-default text-md-left decor-text" data-content="Error pago pedido">
          <div className="container">
            <div className="row justify-content-sm-center align-items-center">
              <div className="col-12 text-center">
                <h2 className="heading-decorated">Pedido no procesado</h2>
              </div>
              <div className="col-12">
                <div className="alert alert-danger fade show" role="alert"><strong>ERROR!</strong> El pago de tu pedido no
                se ha realizado correctamente.</div>
                <p>Esto puede haber ocurrido si no ha finalizado todos los pasos del proceso de pago de su pedido.</p>
                <p>Por favor, intenteló de nuevo <Link to={environments.BASE_PATH.concat('orderConfirm')}>aquí</Link>.</p>
                <p>De no haber sido así, pongasé en contacto con nosotros y le ayudaremos encantados.</p>
                <p>Muchas gracias por confiar en <strong>KUADRUKIS</strong>.</p>
              </div>
            </div>
          </div>
        </section>
        <PreFooter />
        <Footer />
      </div>
    );
  }

}

export default OrderConfirmResultKo;