import React from 'react';
import loadjs from 'loadjs';
import env from '../../environments';
import Header from '../../commons/Header';
import PreFooter from '../../commons/PreFooter';
import Footer from '../../commons/Footer';
import CartDetail from './partials/CartDetail';
import CartResume from './partials/CartResume';
import { getOrder, setOrder } from '../../utils/apiLocal';

class Cart extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      order: getOrder()
    };

    this.handleOnRefresh = this.handleOnRefresh.bind(this);
  }

  componentDidMount() {
    loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
      window.scrollTo(0, 0);
    });
  }

  handleOnRefresh(newOrder) {
    setOrder(newOrder);
    this.setState({ order: newOrder });
  }

  render() {
    return (
      <div className="page">
        <Header />
        <section className="section-xs bg-default text-md-left decor-text" data-content="Carrito">
          <div className="container">
            <div className="row justify-content-sm-center align-items-center">
              <div className="col-12 text-center">
                <h2 className="heading-decorated">Tu carrito</h2>
              </div>
            </div>
            <div className="row row-50 justify-content-sm-left">
              <div className="col-md-7 col-lg-8">
                <h6 className="heading-decorated">Productos añadidos</h6>
                <CartDetail localOrder={this.state.order} onRefresh={this.handleOnRefresh} />
              </div>
              <div className="col-md-5 col-lg-4">
                <h6 className="heading-decorated">Resumen</h6>
                <CartResume localOrder={this.state.order} />
              </div>
            </div>
          </div>
        </section>
        <PreFooter />
        <Footer />
      </div>
    );
  }
}

export default Cart;