import React from 'react';
import 'bootstrap/dist/js/bootstrap';
import environments from '../../../environments';

const OrderPayment = () => {

  return (
    <div className="card card-custom py-4">
      <div className="card-custom-heading" id="accordionHeading3">
        <h6 className="card-custom-title heading-decorated">
          <a className="collapsed" role="button" data-toggle="collapse" data-target="#accordionCollapse3"
            href="#accordionCollapse3" aria-controls="accordionCollapse3" aria-expanded="false"
          >Forma de pago</a>
        </h6>
      </div>
      <div className="card-custom-collapse collapse" id="accordionCollapse3"
        aria-labelledby="accordionHeading3" data-parent="#accordion">
        <div className="card-custom-body">
          <div className="row">
            <div className="col-md-12 text-center">
              <img width="100" className="img-fluid mx-auto d-block" src={environments.BASE_ASSETS_URL.concat('images/order/creditCards.png')}
                alt="Pago con tarjeta de crédito" />
              <p>
                <strong>Pago con tarjeta de crédito Visa o Mastercard</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default OrderPayment;