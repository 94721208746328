const data = {
  address: {
    name: 'Avda. General Bañuls, 33. BIGASTRO - ALICANTE',
    url: 'https://www.google.es/maps/place/Carrer+General+Ba%C3%B1uls,+33,+03380+Bigastro,+Alicante',
    title: 'Ver en Google Maps'
  },
  phone: {
    url: 'tel:+34966770032',
    title: 'Llamar al teléfono (+34) 966 77 00 32',
    name: '(+34) 966 77 00 32'
  },
  social: {
    facebook: {
      url: 'https://www.facebook.com/kuadrukis',
      title: 'Ir a nuestra página en Facebook'
    },
    youtube: {
      url: 'https://www.youtube.com/channel/UCTj4cMueBc-O84CSAksQWxQ',
      title: 'Ir a nuestro canal en Youtube'
    },
    instagram: {
      url: 'https://www.instagram.com/kuadrukis/',
      title: 'Ir a nuestro Instagram'
    }
  },
  openingHours: 'Lu-Vi: 9 am - 7 pm',
  contactEmail: 'info@kuadrukis.com'
};

export default data;