import React from 'react';
import loadjs from 'loadjs';
import env from '../../environments';
import Header from '../../commons/Header';
import PreFooter from '../../commons/PreFooter';
import Footer from '../../commons/Footer';
import { Link } from 'react-router-dom';
import environments from '../../environments';
import { setOrder } from '../../utils/apiLocal';

class OrderConfirmResultOk extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
      window.scrollTo(0, 0);
    });
  }

  componentWillMount() {
    this.saveOrderAsPaid();
  }

  saveOrderAsPaid() {
    setOrder({
      orderId: null,
      items: []
    });
  }

  render() {
    return (
      <div className="page">
        <Header />
        <section className="section-xs bg-default text-md-left decor-text" data-content="Pedido realizado">
          <div className="container">
            <div className="row justify-content-sm-center align-items-center">
              <div className="col-12 text-center">
                <h2 className="heading-decorated">Pedido realizado correctamente</h2>
              </div>
              <div className="col-12">
                <div className="alert alert-success fade show" role="alert"><strong>OK!</strong> Tu pedido ha sido tramitado correctamente.</div>
                <p>Puedes acceder al detalle de este pedido y cualquiera de los que hayas realizado con nosotros a través de tu zona zona
                  privada de usuario, en la sección de <Link to={environments.BASE_PATH.concat('myAccount/orders')}>Pedidos realizados</Link>.</p>
                <p>Atenderemos tu pedido con la mayor brevedad posible para que disfrutes de tus productos en casa cuanto antes.</p>
                <p>Muchas gracias por confiar en <strong>KUADRUKIS</strong>.</p>
              </div>
            </div>
          </div>
        </section>
        <PreFooter />
        <Footer />
      </div>
    );
  }

}

export default OrderConfirmResultOk;