/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import api from '../../../../utils/api';

const GridUsers = forwardRef((props, ref) => {

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorText, setErrorText] = useState(false);

  const defaultData = {
    page: 1,
    rowsPage: 10,
    columnOrder: 'firstName',
    order: 'ASC',
    filters: [{
      name: 'firstName',
      type: '!=',
      value: ""
    }],
    totalPages: [],
    resultData: []
  };

  if (props.userId !== null) {
    defaultData.filters.push({
      name: 'u.id',
      type: '=',
      value: props.userId
    });
  }

  const [data, setData] = useState(defaultData);

  const reloadUsersData = () => {
    api.post('users', data)
      .then(response => {
        if (response.statusCode === 200) {
          const totalPages = Math.ceil(response.data.totalRows / response.data.rowsPage);
          const totalPagesArray = [];

          for (let i = 1; i <= totalPages; i++) {
            totalPagesArray.push(i);
          }

          response.data.totalPages = totalPagesArray;

          response.data.columnOrder = data.columnOrder;
          response.data.order = data.order;
          response.data.filters = data.filters;

          setData(response.data);
        } else {
          setData(defaultData);
        }
      });
  };

  useEffect(() => {
    reloadUsersData();
  }, []);

  useImperativeHandle(ref, () => ({
    search(searchValues) {
      const currentFilters = [];

      if (searchValues.firstName !== '') {
        currentFilters.push({
          name: 'firstName',
          type: 'like',
          value: '%'.concat(searchValues.firstName).concat('%')
        });
      }

      if (searchValues.email !== '') {
        currentFilters.push({
          name: 'email',
          type: 'like',
          value: '%'.concat(searchValues.email).concat('%')
        });
      }

      if (searchValues.status !== '') {
        currentFilters.push({
          name: 'active',
          type: '=',
          value: searchValues.status
        });
      }
      if (currentFilters.length === 0) {
        currentFilters.push(defaultData.filters[0]);
      }

      data.filters = currentFilters;
      data.page = 1;
      setData(data);
      reloadUsersData();
    }
  }));

  const getUserStatus = (userData) => {
    let title = '';

    switch (userData.active) {
      case 'S':
        title = 'Activo';
        break;
      case 'N':
        title = 'Inactivo';
        break;
    }

    return title;
  };

  const refreshGrid = () => {
    setData(data);
    reloadUsersData();
  }

  const handleOnPreviousButton = () => {
    data.page = data.page - 1;
    refreshGrid();
  };

  const handleOnNextButton = () => {
    data.page = data.page + 1;
    refreshGrid();
  };

  const handleOnPageButton = page => {
    data.page = page;
    refreshGrid();
  };

  const handleColumnSort = (fieldNameToSort) => {
    if (data.columnOrder === fieldNameToSort) {
      data.order = data.order === 'DESC' ? 'ASC' : 'DESC';
    } else {
      data.order = 'ASC';
    }

    data.columnOrder = fieldNameToSort;
    data.page = 1;

    refreshGrid();
  };

  const handleOnClickStatus = (userId, userStatus) => {
    api.post('users/status', {
      id: userId,
      active: userStatus === 'S' ? false : true
    })
      .then(response => {
        if (response.statusCode === 200) {
          setErrorText('');
          setShowSuccessAlert(true);
          setTimeout(() => {
            setShowSuccessAlert(false);
          }, 10000);
          refreshGrid();
        } else {
          setErrorText(response.error.description);
          setShowErrorAlert(true);
          setTimeout(() => {
            setShowErrorAlert(false);
          }, 10000);
        }
      });
  };

  const getFileNameByContentDisposition = (contentDisposition) => {
    const regex = /filename[^;=\n]*=(UTF-8(['"]*))?(.*)/;
    const matches = regex.exec(contentDisposition);
    let filename = "";

    if (matches != null && matches[3]) {
      filename = matches[3].replace(/['"]/g, "");
    }

    return decodeURI(filename);
  };

  const downloadFileFromServiceResponse = (response, fileType, defaultFilename, options = {}) => {
    const { minSize, forceDefaultName } = options;
    const newMinSize = minSize || 0;

    return response.blob().then(value => {
      if (value.size > newMinSize) {

        const newBlob = new Blob([value], { type: fileType });
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        let filename = getFileNameByContentDisposition(response.headers.get("Content-disposition"));

        if (forceDefaultName || filename === null || filename === "") {
          filename = defaultFilename;
        }

        link.href = data;
        link.download = filename;
        link.setAttribute("type", "hidden");
        document.body.appendChild(link);
        link.click();
        link.remove();
      }

      return value;
    });
  };

  const handleOnClickPdf = user => {
    api.get(`users/pdf/${user.id}`, {}, 'application/pdf').then(response => {
      downloadFileFromServiceResponse(response, "application/pdf", `pegatina${user.firstName}${user.lastName.replace(' ', '')}.pdf`);
    });
  };

  return (

    <div className="col-md-12 mt-3">
      {showSuccessAlert && <div className="alert alert-success fade show" role="alert">
        <strong>OK!</strong> Se ha actualizado el estado del usuario.
        </div>}
      {showErrorAlert && <div className="alert alert-danger fade show" role="alert">
        <strong>ERROR!</strong> No se ha podido modificar el estado del usuario. Por favor, revisa esto:<br />{errorText}
      </div>}
      <table className="table table-striped table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por Id. Usuario" onClick={() => handleColumnSort('id')}>
                ID Cliente {data.columnOrder === 'id' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por nombre" onClick={() => handleColumnSort('firstName')}>
                Nombre {data.columnOrder === 'firstName' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por apellido" onClick={() => handleColumnSort('lastName')}>
                Apellido {data.columnOrder === 'lastName' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por email" onClick={() => handleColumnSort('email')}>
                Email {data.columnOrder === 'email' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por dirección" onClick={() => handleColumnSort('postalAddress')}>
                Dirección {data.columnOrder === 'postalAddress' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por teléfono" onClick={() => handleColumnSort('telephoneContact')}>
                Teléfono {data.columnOrder === 'telephoneContact' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" href="#" title="Ordenar por estado" onClick={() => handleColumnSort('active')}>
                Estado {data.columnOrder === 'active' && (
                  <span className={`icon-xs mdi mdi-sort-${data.order === 'DESC' ? 'descending' : 'ascending'}`}></span>
                )}
              </a>
            </th>
            <th scope="col">
              <a className="icon-gray-darker" title="Activar/Desactivar usuario">
                Acción
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data.resultData.map((item, index) => (
            <tr key={index}>
              <th scope="row">{item.id}</th>
              <td>{item.firstName}</td>
              <td>{item.lastName}</td>
              <td>{item.email}</td>
              <td>{item.postalAddress} - {item.town.postalCode} {item.town.town.replace('&Aacute;', 'Á').replace('&Eacute;', 'É')
                .replace('&Iacute;', 'Í').replace('&Oacute;', 'Ó').replace('&Uacute;', 'Ú')
                .replace('&Ntilde;', 'Ñ')} ({item.town.province.province})</td>
              <td>{item.telephoneContact}</td>
              <td>{getUserStatus(item)}</td>
              <td>
                {item.active === 'S' && (
                  <a className="button-link icon-md mdi linear-icon-user-lock" href="#" title="Desactivar usuario" onClick={() => handleOnClickStatus(item.id, item.active)}></a>
                )}
                {item.active === 'N' && (
                  <a className="button-link icon-md mdi linear-icon-user" href="#" title="Activar usuario" onClick={() => handleOnClickStatus(item.id, item.active)}></a>
                )}
                <a className="button-link icon-md mdi mdi-file-pdf" href="#" title="Descargar pegatina" onClick={() => handleOnClickPdf(item)}></a>
              </td>
            </tr>
          ))}
          {data.resultData.length === 0 && (
            <tr>
              <td colSpan="8">
                <div className="alert alert-danger fade show" role="alert">
                  No se han encontrado usuarios que mostrar para la búsqueda realizada.
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          <li className={`page-item ${data.page === 1 ? 'disabled' : ''}`}>
            <a className="page-link" href="#" tabIndex="-1" onClick={handleOnPreviousButton}>Anterior</a>
          </li>
          {data.totalPages.map((p, index) => (
            <li key={index} className={`page-item ${data.page === p ? 'active' : ''}`}>
              <a className="page-link" href="#" onClick={() => data.page !== p ? handleOnPageButton(p) : null}>
                {p} {data.page === p && (<span className="sr-only">(current)</span>)}
              </a>
            </li>
          ))}
          <li className={`page-item ${data.page === data.totalPages.length ? 'disabled' : ''}`}>
            <a className="page-link" href="#" onClick={handleOnNextButton}>Siguiente</a>
          </li>
        </ul>
      </nav>
    </div>
  );
});

export default GridUsers;