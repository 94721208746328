import React from 'react';
import 'bootstrap/dist/js/bootstrap';
import { Link } from 'react-router-dom';
import { getUser } from '../../../utils/apiLocal';
import environments from '../../../environments';

const OrderAddress = () => {

  const userSession = getUser();

  return (
    <div className="card card-custom pb-4">
      <div className="card-custom-heading" id="accordionHeading2">
        <h6 className="card-custom-title heading-decorated">
          <a role="button" data-toggle="collapse" data-target="#accordionCollapse2"
            href="#accordionCollapse2" aria-controls="accordionCollapse2" aria-expanded="true"
          >Dirección de entrega</a>
        </h6>
      </div>
      <div className="card-custom-collapse collapse show" id="accordionCollapse2"
        aria-labelledby="accordionHeading2" data-parent="#accordion">
        <div className="card-custom-body">
          <p>
            <strong>
              El pedido será entregado con los siguientes datos <Link to={environments.BASE_PATH.concat('myAccount/personalData')}>[modificar]</Link>:
              </strong>
          </p>
          <ul className="list-sm contact-info">
            <li>
              <dl className="list-terms-inline row">
                <dt className="col-md-4">Nombre y apellidos</dt>
                <dd className="col-md-8">{userSession.firstName.concat(' ').concat(userSession.lastName)}</dd>
              </dl>
            </li>
            <li>
              <dl className="list-terms-inline row">
                <dt className="col-md-4">Dirección de entrega</dt>
                <dd className="col-md-8">
                  {userSession.postalAddress.concat(' - ').concat(userSession.town.postalCode)
                    .concat(' (').concat(userSession.town.town).concat(') ')
                    .concat(userSession.town.province.province)}
                </dd>
              </dl>
            </li>
            <li>
              <dl className="list-terms-inline row">
                <dt className="col-md-4">Teléfono de contacto</dt>
                <dd className="col-md-8">{userSession.telephoneContact}</dd>
              </dl>
            </li>
            <li>
              <dl className="list-terms-inline row">
                <dt className="col-md-4">Correo electrónico</dt>
                <dd className="col-md-8">{userSession.email}</dd>
              </dl>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

};

export default OrderAddress;