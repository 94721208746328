import React from 'react';
import env from '../../../environments';

function Inspiration() {
  return (
    <section id="inspiration" className="section-md text-center bg-default">
      {/* <div className="bg-decor d-flex align-items-center justify-content-end"
        data-parallax-scroll="{&quot;y&quot;: 50, &quot;smoothness&quot;: 30}">
        <img src={env.BASE_ASSETS_URL.concat('images/bg-decor-4.png')} alt="Ideas para inspirarte" />
      </div> */}
      <div className="bg-square6">
        <img src={env.BASE_ASSETS_URL.concat('images/home/square6.png')} alt="" />
      </div>
      <div className="bg-line2">
        <img src={env.BASE_ASSETS_URL.concat('images/home/line2.png')} alt="" />
      </div>
      <div className="container-fluid">
        <h4 className="heading-decorated">Ideas para inspirarte</h4>
        <div className="row row-30">
          <div id="carousel-example" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner row w-100 mx-auto" role="listbox">
              <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3 active">
                <a href={env.BASE_ASSETS_URL.concat('images/company-original-1-1240x744.jpg')} data-lightgallery="item"
                  title="Opción de salón 1">
                  <img src={env.BASE_ASSETS_URL.concat('images/company-1-417x250.jpg')} className="img-fluid mx-auto d-block"
                    alt="Opción de salón 1" />
                </a>
              </div>
              <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                <a href={env.BASE_ASSETS_URL.concat('images/company-original-2-1240x708.jpg')} data-lightgallery="item"
                  title="Opción de salón 2">
                  <img src={env.BASE_ASSETS_URL.concat('images/company-2-417x250.jpg')} className="img-fluid mx-auto d-block"
                    alt="Opción de salón 2" />
                </a>
              </div>
              <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                <a href={env.BASE_ASSETS_URL.concat('images/company-original-3-1200x1200.jpg')} data-lightgallery="item"
                  title="Opción de salón 3">
                  <img src={env.BASE_ASSETS_URL.concat('images/company-3-417x250.jpg')} className="img-fluid mx-auto d-block"
                    alt="Opción de salón 3" />
                </a>
              </div>
              <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                <a href={env.BASE_ASSETS_URL.concat('images/company-original-4-800x600.jpg')} data-lightgallery="item"
                  title="Opción de salón 4">
                  <img src={env.BASE_ASSETS_URL.concat('images/company-4-417x250.jpg')} className="img-fluid mx-auto d-block"
                    alt="Opción de salón 4" />
                </a>
              </div>
              <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                <a href={env.BASE_ASSETS_URL.concat('images/company-original-5-600x899.jpg')} data-lightgallery="item"
                  title="Opción de habitación 1">
                  <img src={env.BASE_ASSETS_URL.concat('images/company-5-417x250.jpg')} className="img-fluid mx-auto d-block"
                    alt="Opción de habitación 1" />
                </a>
              </div>
              <div className="carousel-item col-12 col-sm-6 col-md-4 col-lg-3">
                <a href={env.BASE_ASSETS_URL.concat('images/company-original-6-1280x720.jpg')} data-lightgallery="item"
                  title="Opción de habitación 2">
                  <img src={env.BASE_ASSETS_URL.concat('images/company-6-417x250.jpg')} className="img-fluid mx-auto d-block"
                    alt="Opción de habitación 2" />
                </a>
              </div>
            </div>
            <a className="carousel-control-prev" href="#carousel-example" role="button" data-slide="prev"
              title="Ver más ideas hacia atrás">
              <span className="icon-md linear-icon-chevron-left" aria-hidden="true"></span>
              <span className="sr-only">Anterior</span>
            </a>
            <a className="carousel-control-next" href="#carousel-example" role="button" data-slide="next"
              title="Ver más ideas hacia adelante">
              <span className="icon-md linear-icon-chevron-right" aria-hidden="true"></span>
              <span className="sr-only">Siguiente</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Inspiration;