import React from 'react';
import env from '../../../environments';

function About() {

  const image = 'url('.concat(env.BASE_ASSETS_URL).concat('images/bg-image-1.jpg)');

  return (
    <section id="about" name="about" className="bg-gray-lighter object-wrap">
      {/* <div className="bg-decor d-flex align-items-center justify-content-start"
        data-parallax-scroll="{&quot;y&quot;: 50,  &quot;smoothness&quot;: 30}">
        <img src={env.BASE_ASSETS_URL.concat('images/bg-decor-4.png')} alt="" />
      </div> */}
      <div className="section-lg">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <h4 className="heading-decorated">Quienes somos</h4>
              <p><strong>KUADRUKIS</strong> es una empresa formada por un grupo de jóvenes inquietos, emprendedores y con
                iniciativa que
                decidieron dar respuesta a un servicio muy demandando.</p>
              <div className="row row-30">
                <div className="col-xl-6">
                  <article className="blurb blurb-minimal">
                    <div className="unit flex-row unit-spacing-md">
                      <div className="unit-left">
                        <div className="blurb-minimal__icon"><span className="icon linear-icon-picture"></span></div>
                      </div>
                      <div className="unit-body">
                        <p className="blurb__title heading-6">Impresiones</p>
                        <p>Ofrecemos poder imprimir en el material que prefieras las fotos o imágenes que más te gusten,
                        de forma personalizada para que puedas decorar ese rinconcito tan especial de tu hogar o dar
                          un toque diferente a tu negocio o local.</p>
                      </div>
                    </div>
                  </article>
                </div>
                <div className="col-xl-6">
                  <article className="blurb blurb-minimal">
                    <div className="unit flex-row unit-spacing-md">
                      <div className="unit-left">
                        <div className="blurb-minimal__icon"><span className="icon linear-icon-users2"></span></div>
                      </div>
                      <div className="unit-body">
                        <p className="blurb__title heading-6">Equipos de expertos</p>
                        <p>Con más de 10 años de experiencia en la impresión digital con sede en Bigastro (Alicante) y
                          provistos de las instalaciones necesarias, así como de la maquinaría más innovadora.</p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
              {/* <div className="row justify-content-md-center">
                <div className="col-md-12">
                  <div className="progress-linear progress-linear-modern">
                    <div className="progress-header">
                      <p>Dedicación</p><span className="progress-value">100</span>
                    </div>
                    <div className="progress-bar-linear-wrap">
                      <div className="progress-bar-linear"></div>
                    </div>
                  </div>
                  <div className="progress-linear progress-linear-modern">
                    <div className="progress-header">
                      <p>Experiencia</p><span className="progress-value">65</span>
                    </div>
                    <div className="progress-bar-linear-wrap">
                      <div className="progress-bar-linear"></div>
                    </div>
                  </div>
                  <div className="progress-linear progress-linear-modern">
                    <div className="progress-header">
                      <p>Creatividad</p><span className="progress-value">86</span>
                    </div>
                    <div className="progress-bar-linear-wrap">
                      <div className="progress-bar-linear"></div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="object-wrap__body object-wrap__body-sizing-1 object-wrap__body-md-right bg-image"
        style={{ backgroundImage: image }}></div>
      <div className="bg-about-square-3">
        <img src={env.BASE_ASSETS_URL.concat('images/home/square3.png')} alt="" />
      </div>
      <div className="bg-about-square-4">
        <img src={env.BASE_ASSETS_URL.concat('images/home/square4.png')} alt="" />
      </div>
      <div className="bg-about-square-5">
        <img src={env.BASE_ASSETS_URL.concat('images/home/square5.png')} alt="" />
      </div>
    </section>
  );
}

export default About;