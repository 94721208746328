import LZString from 'lz-string';

const KUADRUKIS_LOCAL_KEY = 'KUADRUKIS_LOCAL_KEY';
const numberMinutesToExpire = 120;

const getNewExpiredDatetime = () => {
  return new Date(new Date().getTime() + numberMinutesToExpire * 60000).getTime();
};

const getOrder = () => {
  const defaultkuadrukisData = {
    user: null,
    order: {
      orderId: null,
      items: []
    },
    expiredDatetime: getNewExpiredDatetime()
  };

  let kuadrukisData = localStorage.getItem(KUADRUKIS_LOCAL_KEY) && localStorage.getItem(KUADRUKIS_LOCAL_KEY).length > 0 ?
                      JSON.parse(LZString.decompress(localStorage.getItem(KUADRUKIS_LOCAL_KEY))) || JSON.parse(localStorage.getItem(KUADRUKIS_LOCAL_KEY)) : defaultkuadrukisData;

  if (kuadrukisData.order.items.length > 0) {
    if (kuadrukisData.expiredDatetime < new Date().getTime()) {
      kuadrukisData.order = {
        orderId: null,
        items: []
      };
      setOrder(kuadrukisData.order);
    }
  }

  return kuadrukisData.order;
};

const setOrder = (order) => {
  const defaultkuadrukisData = {
    user: null,
    order: {
      orderId: null,
      items: []
    },
    expiredDatetime: getNewExpiredDatetime()
  };
  const kuadrukisData = localStorage.getItem(KUADRUKIS_LOCAL_KEY) && localStorage.getItem(KUADRUKIS_LOCAL_KEY).length > 0 ?
                        JSON.parse(LZString.decompress(localStorage.getItem(KUADRUKIS_LOCAL_KEY))) || JSON.parse(localStorage.getItem(KUADRUKIS_LOCAL_KEY)) : defaultkuadrukisData;
  const newkuadrukisData = {
    user: kuadrukisData.user,
    order,
    expiredDatetime: getNewExpiredDatetime()
  };

  localStorage.setItem(KUADRUKIS_LOCAL_KEY, LZString.compress(JSON.stringify(newkuadrukisData)));
};

const getUser = () => {
  const defaultkuadrukisData = {
    user: null,
    order: {
      orderId: null,
      items: []
    },
    expiredDatetime: getNewExpiredDatetime()
  };
  let kuadrukisData = localStorage.getItem(KUADRUKIS_LOCAL_KEY) && localStorage.getItem(KUADRUKIS_LOCAL_KEY).length > 0 ?
                      JSON.parse(LZString.decompress(localStorage.getItem(KUADRUKIS_LOCAL_KEY))) || JSON.parse(localStorage.getItem(KUADRUKIS_LOCAL_KEY)) : defaultkuadrukisData;

  if (kuadrukisData.user !== null) {
    if (kuadrukisData.expiredDatetime < new Date().getTime()) {
      kuadrukisData.user = null;
      setUser(kuadrukisData.user);
    }
  }

  return kuadrukisData.user;
};

const setUser = (user) => {
  const kuadrukisData = localStorage.getItem(KUADRUKIS_LOCAL_KEY) && localStorage.getItem(KUADRUKIS_LOCAL_KEY).length > 0 ?
  JSON.parse(LZString.decompress(localStorage.getItem(KUADRUKIS_LOCAL_KEY))) || JSON.parse(localStorage.getItem(KUADRUKIS_LOCAL_KEY)) : null;
  const newkuadrukisData = {
    order: kuadrukisData ? kuadrukisData.order : {
      orderId: null,
      items: []
    },
    user,
    expiredDatetime: getNewExpiredDatetime()
  };

  localStorage.setItem(KUADRUKIS_LOCAL_KEY, LZString.compress(JSON.stringify(newkuadrukisData)));
};

const hasRol = (rolName) => {
  const user = getUser();

  return user.roles.filter(rol => rol === rolName).length > 0;
}

export {
  getOrder,
  setOrder,
  getUser,
  setUser,
  hasRol,
};