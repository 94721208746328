import React from 'react';
import loadjs from 'loadjs';
import env from '../../environments';
import Header from '../../commons/Header';
import PreFooter from '../../commons/PreFooter';
import Footer from '../../commons/Footer';
import api from '../../utils/api';
import { withRouter, Link } from 'react-router-dom';

class ConfirmRegistration extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showSuccessAlert: false,
      showErrorAlert: false,
      errorText: ''
    };
  }

  componentDidMount() {
    loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
      window.scrollTo(0, 0);
    });

    this.confirmRegistration();
  }

  confirmRegistration() {
    const query = new URLSearchParams(this.props.location.search);

    api.get('myAccount/confirmRegistration', {
      email: query.get('email'),
      token: query.get('token')
    })
      .then(response => {
        const currentState = this.state;

        if (response.statusCode === 200) {
          currentState.errorText = '';
          currentState.showSuccessAlert = true;
        } else {
          currentState.errorText = response.error.description;
          currentState.showErrorAlert = true;
        }

        this.setState(currentState);
      });
  }

  render() {
    return (
      <div className="page">
        <Header />
        <section className="section-xs bg-default text-md-left decor-text" data-content="Usuario">
          <div className="container">
            <div className="row justify-content-sm-center align-items-center">
              <div className="col-12 text-center">
                <h2 className="heading-decorated">Confirmación nuevo registro usuario</h2>
              </div>
            </div>
            <div className="row justify-content-sm-left">
              <div className="col-md-12">
                {this.state.showSuccessAlert && <div className="alert alert-success fade show" role="alert">
                  <strong>OK!</strong> Tu cuenta se ha activado correctamente. Ya puedes ir a la sección
                  de <Link to={env.BASE_PATH.concat('login')} title="Iniciar sesión">login</Link> y utilizar tu usuario.
                </div>}
                {this.state.showErrorAlert && <div className="alert alert-danger fade show" role="alert">
                  <strong>ERROR!</strong> No se ha podido activar tu cuenta. Por favor, revisa esto: {this.state.errorText}
                </div>}
              </div>
            </div>
          </div>
        </section>
        <PreFooter />
        <Footer />
      </div>
    );
  }
}

export default withRouter(ConfirmRegistration);