import React from 'react';
import PropTypes from 'prop-types';

function GoogleMaps({ fromFooter }) {
  let containerClassName = 'google-map';

  if (fromFooter) {
    containerClassName = 'google-map-footer';
  }

  return (
    <div className={containerClassName}>
      <iframe title="Mapa de nuestra situación"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3141.3205247201017!2d-0.9029467846740499!3d38.06291587970802!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd63a203d716f537%3A0xe4b5eca4a81a0ba2!2sCarrer%20General%20Ba%C3%B1uls%2C%2033%2C%2003380%20Bigastro%2C%20Alicante!5e0!3m2!1ses!2ses!4v1586344056611!5m2!1ses!2ses"
        width="100%" height="100%" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false"
        tabindex="0"></iframe>
    </div>
  );
}

GoogleMaps.propTypes = {
  fromFooter: PropTypes.bool.isRequired,
};

GoogleMaps.defaultProps = {
  fromFooter: false,
};

export default GoogleMaps;