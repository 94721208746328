import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import environments from '../../../environments';

function MyAccountSections({ selected }) {

  return (
    <div className="col-md-4 col-lg-5">
      <h6 className="heading-decorated">Secciones</h6>
      <ul className="list-xxs small mt-3">
        <li>
          <Link className={selected === 'personalData' ? 'active' : ''}
            to={environments.BASE_PATH.concat('myAccount/personalData')}>Datos personales</Link>
        </li>
        <li>
          <Link className={selected === 'changePassword' ? 'active' : ''}
            to={environments.BASE_PATH.concat('myAccount/changePassword')}>Cambiar contraseña</Link>
        </li>
        <li>
          <Link className={selected === 'orders' ? 'active' : ''}
            to={environments.BASE_PATH.concat('myAccount/orders')}>Pedidos realizados</Link>
        </li>
      </ul>
    </div>
  );

}

MyAccountSections.propTypes = {
  selected: PropTypes.string
};

MyAccountSections.defaultProps = {
  selected: 'personalData'
};


export default MyAccountSections;