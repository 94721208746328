import React, { useState } from 'react';
import 'bootstrap/dist/js/bootstrap';
import { useFormik } from 'formik';
import api from '../../../utils/api';
import { setUser } from '../../../utils/apiLocal';
import { withRouter } from 'react-router-dom';
import environments from '../../../environments';

const LoginForm = ({ history }) => {

  const [errorAlert, setErrorAlert] = useState('');
  const [errorRecoveryPasswordAlert, setErrorRecoveryPasswordAlert] = useState('');
  const [showSuccessRecoveryPasswordAlert, setShowSuccessRecoveryPasswordAlert] = useState(false);
  const [showRecoveryPassword, setShowRecoveryPassword] = useState(false);

  const validate = values => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Tu correo electrónico es obligatorio';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Correo electrónico con formato no válido';
    }

    if (!values.password) {
      errors.password = 'Tu contraseña es obligatoria';
    } else if (values.password.length < 8) {
      errors.password = 'Debe tener 8 caracteres o más';
    }

    return errors;
  };

  const handleOnSubmit = (values) => {
    setErrorAlert('');

    api.post('users/login', values)
      .then(response => {
        window.scrollTo(0, document.getElementById('loginForm').offsetTop + 100);

        if (response.statusCode === 200) {
          setUser(response.data.user);

          const pathname = history.location.params && history.location.params.redirect ?
            environments.BASE_PATH.concat(history.location.params.redirect) :
            environments.BASE_PATH;

          history.push({ pathname });
        } else {
          formik.setFieldValue('password', '');

          setErrorAlert(response.error.description);
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validate,
    onSubmit: handleOnSubmit
  });

  const validateRecoveryPassword = values => {
    const errors = {};

    if (!values.emailRecoveryPassword) {
      errors.emailRecoveryPassword = 'Tu correo electrónico es obligatorio';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailRecoveryPassword)) {
      errors.emailRecoveryPassword = 'Correo electrónico con formato no válido';
    }

    return errors;
  };

  const handleOnSubmitRecoveryPassword = (values) => {
    setErrorRecoveryPasswordAlert('');

    api.post('users/recovery', {
      email: values.emailRecoveryPassword
    })
      .then(response => {
        window.scrollTo(0, document.getElementById('recoveryPasswordForm').offsetTop + 100);

        if (response.statusCode === 200) {
          setShowSuccessRecoveryPasswordAlert(true);
        } else {
          formikRecoveryPassword.setFieldValue('email', '');

          setErrorRecoveryPasswordAlert(response.error.description);
        }
      });
  };

  const formikRecoveryPassword = useFormik({
    initialValues: {
      emailRecoveryPassword: ''
    },
    validate: validateRecoveryPassword,
    onSubmit: handleOnSubmitRecoveryPassword
  });

  const handleOnClickRecoveryMyPassword = () => {
    setShowRecoveryPassword(!showRecoveryPassword);
  };

  return (
    <div className="col-md-6">
      <h6 className="heading-decorated">Si ya tienes usuario, inicia sesión</h6>
      <form id="loginForm" className="rd-mailform rd-mailform_style-1 form-shadow" onSubmit={formik.handleSubmit}>
        {errorAlert.length > 0 && (
          <div className="alert alert-danger fade show" role="alert">
            <strong>ERROR!</strong> {errorAlert}
          </div>
        )}
        <div className="form-wrap form-wrap_icon linear-icon-envelope">
          <input className="form-input" id="email" type="email" name="email" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.email} />
          {formik.errors.email ? <span className="form-validation">{formik.errors.email}</span> : null}
          <label className="form-label" htmlFor="email">Tu correo electrónico</label>
        </div>
        <div className="form-wrap form-wrap_icon linear-icon-lock">
          <input className="form-input" id="password" type="password" name="password" onChange={formik.handleChange}
            onBlur={formik.handleBlur} value={formik.values.password} />
          {formik.errors.password ? <span className="form-validation">{formik.errors.password}</span> : null}
          <label className="form-label" htmlFor="password">Tu contraseña</label>
        </div>
        <div className="text-right mt-3">
          <button className="button button-black button-shadow" type="submit">Entrar</button>
        </div>
      </form>
      <ul className="list-xxs small mt-3">
        <li>
          <a href="#this" className={showRecoveryPassword ? 'active' : ''} onClick={handleOnClickRecoveryMyPassword}>¿Has olvidado tu contraseña?</a>
        </li>
      </ul>
      {showRecoveryPassword && (
        <form id="recoveryPasswordForm" className="rd-mailform rd-mailform_style-1 form-shadow" onSubmit={formikRecoveryPassword.handleSubmit}>
          <p>Si has olvidado tu contraseña, introduce tu correo electrónico y te enviaremos una nueva contraseña autogenerada, con la que más
            tarde podras iniciar sesión y cambiarla si lo deseas.</p>
          {errorRecoveryPasswordAlert.length > 0 && (
            <div className="alert alert-danger fade show" role="alert">
              <strong>ERROR!</strong> {errorRecoveryPasswordAlert}
            </div>
          )}
          {showSuccessRecoveryPasswordAlert && <div className="alert alert-success fade show" role="alert">
            <strong>OK!</strong> Se ha generado una nueva contraseña y se ha enviado a tu cuenta de correo.
          </div>}
          <div className="form-wrap form-wrap_icon linear-icon-envelope">
            <input className="form-input" id="emailRecoveryPassword" type="email" name="emailRecoveryPassword" onChange={formikRecoveryPassword.handleChange}
              onBlur={formikRecoveryPassword.handleBlur} value={formikRecoveryPassword.values.emailRecoveryPassword} />
            {formikRecoveryPassword.errors.emailRecoveryPassword ? <span className="form-validation">{formikRecoveryPassword.errors.emailRecoveryPassword}</span> : null}
          </div>
          <div className="text-right mt-3">
            <button className="button button-black button-shadow" type="submit">Regenerar</button>
          </div>
        </form>
      )}
    </div>
  );

};

LoginForm.propTypes = {

};

LoginForm.defaultProps = {

};

export default withRouter(LoginForm);