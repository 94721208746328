import React from 'react';
import loadjs from 'loadjs';
import env from '../../environments';
import Header from '../../commons/Header';
import PreFooter from '../../commons/PreFooter';
import Footer from '../../commons/Footer';
import PrivacityTitle from './partials/PrivacityTitle';
import PrivacityDetail from './partials/PrivacityDetail';

class Privacity extends React.Component {

  componentDidMount() {
    loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
      window.scrollTo(0, 0);
    });
  }

  render() {
    return (
      <div className="page">
        <Header />
        <PrivacityTitle />
        <PrivacityDetail />
        <PreFooter />
        <Footer />
      </div>
    );
  }
}

export default Privacity;