import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';

function SearchUsers({ onSearch }) {

  const statuses = [
    {
      value: 'S',
      label: 'Activo'
    },
    {
      value: 'N',
      label: 'Inactivo'
    },
  ];

  const formik = useFormik({
    initialValues: {
      firstName: '',
      email: '',
      status: '',
    },
    onSubmit: onSearch
  });

  return (
    <div className="col-md-12">
      <form id="searchUsers" className="rd-mailform rd-mailform_style-1 form-shadow row" onSubmit={formik.handleSubmit}>
        <div className="col-md-4">
          <div className="form-wrap form-wrap_icon linear-icon-man">
            <input className="form-input" id="firstName" type="text" name="firstName" onChange={formik.handleChange}
              onBlur={formik.handleBlur} value={formik.values.firstName} />
            {formik.errors.firstName ? <span className="form-validation">{formik.errors.firstName}</span> : null}
            <label className="form-label" htmlFor="firstName">Nombre</label>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-wrap form-wrap_icon linear-icon-envelope">
            <input className="form-input" id="email" type="text" name="email" onChange={formik.handleChange}
              onBlur={formik.handleBlur} value={formik.values.email} />
            {formik.errors.email ? <span className="form-validation">{formik.errors.email}</span> : null}
            <label className="form-label" htmlFor="email">Email</label>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-wrap form-wrap_icon">
            <select className="custom-select" id="status" name="status" value={formik.values.status} onBlur={formik.handleBlur}
              onChange={formik.handleChange}>
              <option value="" label="Todos" />
              {statuses.map((status, index) => (
                <option key={index} value={status.value} label={status.label} />
              ))}
            </select>
            {formik.errors.status ? <span className="form-validation">{formik.errors.status}</span> : null}
          </div>
        </div>
        <div className="col-md-12 text-right mt-2">
          <button className="button button-black button-shadow" type="submit">Buscar</button>
        </div>
      </form>
    </div>
  );
}

SearchUsers.propTypes = {
  onSearch: PropTypes.func.isRequired
};

SearchUsers.defaultProps = {};

export default SearchUsers;