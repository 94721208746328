/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import data from '../../../staticData';

function ContactDetails() {
  return (
    <div className="col-md-5 col-lg-4">
      <h6 className="heading-decorated">Detalles contacto</h6>
      <ul className="list-sm contact-info">
        <li>
          <dl className="list-terms-inline">
            <dt>Dirección</dt>
            <dd>{data.address.name}</dd>
          </dl>
        </li>
        <li>
          <dl className="list-terms-inline">
            <dt>E-mail</dt>
            <dd>
              <a href={'mailto:'.concat(data.contactEmail)}>{data.contactEmail}</a>
            </dd>
          </dl>
        </li>
        {/* <li>
          <dl className="list-terms-inline">
            <dt>Teléfonos</dt>
            <dd>
              <ul className="list-semicolon d-inline">
                <li>
                  <a href={data.phone.url}>{data.phone.name}</a>
                </li>
              </ul>
            </dd>
          </dl>
        </li> */}
        {/* <li>
          <dl className="list-terms-inline">
            <dt>Horario de apertura</dt>
            <dd>{data.openingHours}</dd>
          </dl>
        </li> */}
        <li>
          <ul className="list-inline-sm">
            <li>
              <a target="_blank" rel="noopener noreferrer" className="icon-sm fa-facebook icon"
                href={data.social.facebook.url} title={data.social.facebook.title}></a>
            </li>

            <li>
              <a target="_blank" rel="noopener noreferrer" className="icon-sm fa-youtube icon"
                href={data.social.youtube.url} title={data.social.youtube.title}></a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default ContactDetails;