import React from 'react';

function TermsAndConditionsDetail() {
  return (
    <section className="section-md bg-gray-lighter decor-text" data-content="Privacity">
      <div className="container">
        <div className="row justify-content-lg-center">
          <div className="col-xs-12 text-center">
            <h4 className="heading-decorated">Detalles de nuestros términos y condiciones</h4>
          </div>
        </div>
        <div className="row justify-content-lg-left">
          <div className="col-xs-12">
            <h6>1. USUARIOS:</h6>
            <p>El acceso y / o Uso del Portal de KUADRUKIS.COM. es atribuye la Condición de Usuario,
            Que Acepta, dicho acceso y / o la USO, las Condiciones Generales de Uso aquí reflejadas.
            Las CITADAS las Condiciones de Aplicación Serán INDEPENDIENTEMENTE de las Condiciones
              Generales de Contratación Que En Su Caso resulten de Obligado Cumplimiento.</p>
            <h6>2. USO DEL PORTAL:</h6>
            <p>El sitio de KUADRUKIS.COM proporciona el acceso a multitud de Informaciones, Servicios,
            Programas o Datos (en adelante, "los Contenidos") En Internet pertenecientes a
            KUADRUKIS.COM y a sus licenciantes, un Los Que EL USUARIO PUEDE Tener acceso. El USUARIO
            ASUME La Responsabilidad del Uso del Sitio. Dicha Responsabilidad se Extiende al registro
            Que fuese Necesario para acceder a este determinados Servicios o Contenidos. En DICHO
            registro el USUARIO Será responsable de Aportar Información veraz y lícita. Como
            consecuencia del registro, al USUARIO se le PUEDE proporcionar Una Contraseña de la Que
            Será responsable, comprometiéndose a Hacer uso diligente y confidencial de la Misma. El
            USUARIO se compromete a Hacer  USO ADECUADO de los Contenidos y Servicios Que KUADRUKIS.COM
            ofrece a Través de su sitio y con Carácter enunciativo Pero no limitativo, un no
            emplearlos para (i) incurrir en actividades ilícitas, Ilegales o contrarias a la buena fe
            y al Orden Público; (Ii): difundir la propaganda o Contenidos de Carácter racista,
            xenófobo, pornográfico-ilegal, de apología del Terrorismo o atentatorio contra los Derechos
            Humanos; (Iii) provocar s daños en los Sistemas Físicos y Lógicos de KUADRUKIS.COM, o de Sus
            Proveedores de Terceras Personas, introducir o: difundir en la red virus informáticos o
            cualesquiera Otros Sistemas Físicos Lógicos o Que sean susceptibles de provocar los s daños
            anteriormente mencionados; (Iv) · intentar Acceder y, en su Caso, Como utilizar las cuentas
            de Correo electrónico de Otros Usuarios y Modificar o Manipular SUS Mensajes. KUADRUKIS.COM
            se reserva el derecho de Borrarse todos Aquellos comentarios y aportaciones Que vulneren el
            respeto a la dignidad de la persona, Que sean discriminatorios, xenófobos, racistas,
            pornográficos, Que atenten contra la juventud o la infancia, El Orden o la Seguridad Pública
            O que , un su juicio, no resultaran adecuados para su publication. En Caso, KUADRUKIS.COM  no
            Será responsable de las opiniones vertidas por los Usuarios a Través de Otras Herramientas
               de Participación.</p>
            <h6>3. Protección de datos:</h6>
            <p>KUADRUKIS.COM cumple con las líneas conductoras de la Ley Orgánica 15/1999, de 13 de diciembre
            de Protección de Datos de Carácter Personal, el Real Decreto 1720/2007, de 21 de diciembre
            por el Que se Aprueba el Reglamento de Desarrollo de la Ley Orgánica Y Demás Normativa Vigente
            en Cada Momento, y Vela por Garantizar el correcto USO y Tratamiento de los Datos Personales
            del usuario. Para Ello, junto A Cada Formulario de recabo de Datos de Carácter Personal, en
            los Servicios Que El USUARIO Pueda Solicitar una KUADRUKIS.COM, de Hará saber al USUARIO de la
            existencia y aceptación de las Condiciones Particulares del Tratamiento de Sus Datos En Cada
            Caso, informándole de la Responsabilidad del fichero CREADO, la Dirección del responsable, la
            Posibilidad de ejercer SUS Derechos de acceso, rectificación, cancelación u Oposición, La
            Finalidad del Tratamiento y las Comunicaciones de Datos una Terceros En Su Caso. Así mismo,
            KUADRUKIS.COM Informa Que da Cumplimiento a la Ley 34/2002, de 11 de julio, de Servicios de la
            Sociedad de la Información y el Comercio Electrónico y le solicitará su Consentimiento al
              Tratamiento de Su Correo electrónico multas estafadores Comerciales En Cada Momento.</p>
              <h6>4. Copyright:</h6>
            <p>El cliente certifica que es propietario o tiene los permisos legales adecuados para imprimir
               la imagen del pedido. La empresa no se responsabilizara de la procedencia ni del contenido de ningún archivo que nos envíen.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TermsAndConditionsDetail;