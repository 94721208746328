import React from 'react';
import Header from '../../commons/Header';
import MainSlider from './partials/MainSlider';
import Slogan from './partials/Slogan';
import Products from './partials/Products';
import About from './partials/About';
import PrintingExperts from './partials/PrintingExperts';
import HomeContact from './partials/HomeContact';
import Inspiration from './partials/Inspiration';
import PreFooter from '../../commons/PreFooter';
import Footer from '../../commons/Footer';

function Home({ location }) {
  return (
    <div className="page">
      <Header isHome />
      <MainSlider />
      <Slogan />
      <Products location={location} />
      <About />
      <PrintingExperts />
      <HomeContact />
      <Inspiration />
      <PreFooter isHome />
      <Footer />

    </div>
  );
}

export default Home;