import React from 'react';
import loadjs from 'loadjs';
import env from '../../environments';
import Header from '../../commons/Header';
import PreFooter from '../../commons/PreFooter';
import Footer from '../../commons/Footer';
import CartResume from '../cart/partials/CartResume';
import { getOrder, setOrder } from '../../utils/apiLocal';
import OrderDetail from './partials/OrderDetail';
import OrderAddress from './partials/OrderAddress';
import OrderPayment from './partials/OrderPayment';
import api from '../../utils/api';

class OrderConfirm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      saveTextError: '',
      order: getOrder(),
      payData: null,
    };
  }

  componentDidMount() {
    loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
      window.scrollTo(0, 0);
    });

    this.saveOrder();
  }

  saveOrder() {
    const orderLines = this.state.order.items.map(item => {
      return {
        productItemId: item.productItem.id,
        image: item.image,
        unitsNumber: item.numberUnits,
        hanger: item.hanger
      };
    });

    api.post('orders/save', {
      orderId: this.state.order.orderId,
      orderLines
    })
      .then(response => {
        if (response.statusCode === 200 || response.statusCode === 201) {
          const newOrder = this.state.order;

          newOrder.orderId = response.data.id;
          newOrder.items = response.data.orderLines.map(line => {
            return {
              description: line.product.description,
              id: line.id,
              image: line.image,
              name: line.product.name,
              numberUnits: line.unitsNumber,
              hanger: line.hanger,
              productItem: {
                id: line.productItemId,
                unitPrice: line.unitPrice,
                title: line.title,
                height: line.height,
                width: line.width
              }
            };
          });

          setOrder(newOrder);
          this.setState({
            saveTextError: '',
            order: newOrder,
            payData: response.data.payData
          });

        } else {
          this.setState({ saveTextError: response.error.description });
        }
      });
  }

  render() {
    return (
      <div className="page">
        <Header />
        <section className="section-xs bg-default text-md-left decor-text" data-content="Carrito">
          <div className="container">
            <div className="row justify-content-sm-center align-items-center">
              <div className="col-12 text-center">
                <h2 className="heading-decorated">Confirmación de pedido</h2>
              </div>
            </div>
            <div className="row row-50 justify-content-sm-left">
              <div className="col-md-7 col-lg-8">
                {this.state.saveTextError.length > 0 && (
                  <div className="alert alert-danger fade show" role="alert">
                    <strong>Importante</strong> Se ha producido un error inesperado al guardar tu pedido.
                  Por favor, intenteló de nuevo más tarde.
                  </div>
                )}
                <div id="accordion">
                  <OrderAddress />
                  <OrderPayment />
                  <OrderDetail localOrder={this.state.order} />
                </div>
              </div>
              <div className="col-md-5 col-lg-4">
                <h6 className="heading-decorated">Resumen</h6>
                <CartResume localOrder={this.state.order} payData={this.state.payData}
                  allowToContinue={this.state.saveTextError.length === 0} />
              </div>
            </div>
          </div>
        </section>
        <PreFooter />
        <Footer />
      </div>
    );
  }

}

export default OrderConfirm;