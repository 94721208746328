import React from 'react';
import env from '../../../environments';

function MainSlider() {

  const sliderImage1 = 'url('.concat(env.BASE_ASSETS_URL).concat('images/home/01.jpg').concat(')');
  const sliderImage2 = 'url('.concat(env.BASE_ASSETS_URL).concat('images/home/02.jpg').concat(')');
  const sliderImage3 = 'url('.concat(env.BASE_ASSETS_URL).concat('images/home/03.jpg').concat(')');
  const sliderImage4 = 'url('.concat(env.BASE_ASSETS_URL).concat('images/home/04.jpg').concat(')');

  return (
    <>
      <svg className="defs">
        <defs>
          <lineargradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="50%" stop-color="#00a096">
              <animate attributename="stop-color" values="#00a096; #00e1d3; #00a096" dur="5s" repeatcount="indefinite">
              </animate>
            </stop>
            <stop offset="100%" stop-color="#00e1d3">
              <animate attributename="stop-color" values="#00e1d3; #00a096; #00e1d3" dur="5s" repeatcount="indefinite">
              </animate>
            </stop>
          </lineargradient>
        </defs>
      </svg>

      <div id="home" className="swiper-container swiper-slider" data-effect="frame-trapeze" data-loop="true"
        data-autoplay="" data-speed="1200" data-mousewheel="false" data-keyboard="true"
        data-frame-fill="url(#gradient1)">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="swiper-slide-img"
              style={{ backgroundImage: sliderImage1 }}
            // data-swiper-anime="{ &quot;animation&quot;: &quot;swiperSlideRotate&quot;, &quot;duration&quot;: 2000, &quot;delay&quot;: 600 }"
            >
            </div>
            <a className="button button-primary button-shadow right" href="#products" data-custom-scroll-to="products"
              title="Ver todos nuestros productos">Crear ahora</a>
            {/*<div className="swiper-slide-caption text-center context-dark">
               <h2
                data-swiper-anime="{ &quot;animation&quot;: &quot;swiperContentStack&quot;, &quot;duration&quot;: 1000, &quot;delay&quot;: 500 }">
                Decora tu hogar con tus fotos preferidas</h2>
              <h6
                data-swiper-anime="{ &quot;animation&quot;: &quot;swiperContentStack&quot;, &quot;duration&quot;: 1000, &quot;delay&quot;: 500 }">
                Entrega en 24-48 horas</h6>
              <div className="group-lg group-middle"
                data-swiper-anime="{ &quot;animation&quot;: &quot;swiperContentStack&quot;, &quot;duration&quot;: 1000, &quot;delay&quot;: 700 }">
                
              </div>
            </div>*/}
          </div>
          <div className="swiper-slide">
            <div className="swiper-slide-img"
              style={{ backgroundImage: sliderImage2 }}
            // data-swiper-anime="{ &quot;animation&quot;: &quot;swiperSlideRotate&quot;, &quot;duration&quot;: 2000, &quot;delay&quot;: 600 }"
            >
            </div>
            <a className="button button-primary button-shadow left" href="#inspiration" data-custom-scroll-to="inspiration"
              title="Ver ideas para inspirarte">Ideas para inspirarte</a>
            {/* <div className="swiper-slide-caption context-dark">
              <h3
                data-swiper-anime="{ &quot;animation&quot;: &quot;swiperContentStack&quot;, &quot;duration&quot;: 1000, &quot;delay&quot;: 500 }">
                ¿Te apetece redecorar tu hogar y estás buscando ideas para personalizar tus paredes?</h3>
              <a className="button button-black button-shadow" href="#inspiration" title="Ver ideas para inspirarte"
                data-custom-scroll-to="inspiration"
                data-swiper-anime="{ &quot;animation&quot;: &quot;swiperContentStack&quot;, &quot;duration&quot;: 1000, &quot;delay&quot;: 700 }">Ideas
              para inspirarte</a>
            </div> */}
          </div>
          <div className="swiper-slide">
            <div className="swiper-slide-img"
              style={{ backgroundImage: sliderImage3 }}
            // data-swiper-anime="{ &quot;animation&quot;: &quot;swiperSlideRotate&quot;, &quot;duration&quot;: 2000, &quot;delay&quot;: 600 }"
            >
            </div>
            <a className="button button-primary button-shadow right" href="#products" data-custom-scroll-to="products"
              title="Ver todos nuestros productos">Ver materiales</a>
            {/* <div className="swiper-slide-caption context-dark">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <h4
                      data-swiper-anime="{ &quot;animation&quot;: &quot;swiperContentStack&quot;, &quot;duration&quot;: 1000, &quot;delay&quot;: 600 }">
                      KUADRUKIS te ofrece varios materiales de la mejor calidad para personalizar e imprimir tus mejores
                    fotos.</h4>
                    <div className="group-lg group-middle"
                      data-swiper-anime="{ &quot;animation&quot;: &quot;swiperContentStack&quot;, &quot;duration&quot;: 1000, &quot;delay&quot;: 700 }">
                      <a className="button button-primary button-shadow" href="#products" data-custom-scroll-to="products"
                        title="Ver todos nuestros productos">Ver materiales</a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="swiper-slide">
            <div className="swiper-slide-img"
              style={{ backgroundImage: sliderImage4 }}
            // data-swiper-anime="{ &quot;animation&quot;: &quot;swiperSlideRotate&quot;, &quot;duration&quot;: 2000, &quot;delay&quot;: 600 }"
            >
            </div>
            <a className="button button-primary button-shadow left" href="#products" data-custom-scroll-to="products"
              title="Ver todos nuestros productos">Crear ahora</a>
            {/* <div className="swiper-slide-caption context-dark">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <h4
                      data-swiper-anime="{ &quot;animation&quot;: &quot;swiperContentStack&quot;, &quot;duration&quot;: 1000, &quot;delay&quot;: 600 }">
                      KUADRUKIS te ofrece varios materiales de la mejor calidad para personalizar e imprimir tus mejores
                    fotos.</h4>
                    <div className="group-lg group-middle"
                      data-swiper-anime="{ &quot;animation&quot;: &quot;swiperContentStack&quot;, &quot;duration&quot;: 1000, &quot;delay&quot;: 700 }">
                      <a className="button button-primary button-shadow" href="#products" data-custom-scroll-to="products"
                        title="Ver todos nuestros productos">Ver materiales</a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="swiper-pagination"></div>
        <div className="swiper-button-prev linear-icon-chevron-left"></div>
        <div className="swiper-button-next linear-icon-chevron-right"></div>
      </div>
    </>
  );
}

export default MainSlider;