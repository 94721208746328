/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import data from '../staticData';

function Footer() {
  return (
    <footer className="footer-corporate bg-gray-darkest">
      <div className="container">
        <div className="footer-corporate__inner">
          <p className="rights">
            &copy; kuadrukis.com <span className="copyright-year"></span>.
                Diseñado en <a href="http://www.kuadrukis.com" target="_blank" rel="noopener noreferrer">www.kuadrukis.com</a>.
              </p>
          <ul className="list-inline-xxs">
            <li>
              <a className="icon icon-xxs icon-primary fa fa-facebook" href={data.social.facebook.url}
                target="_blank" rel="noopener noreferrer" title={data.social.facebook.title}></a>
            </li>
            <li>
              <a className="icon icon-xxs icon-primary fa fa-youtube" title={data.social.youtube.title}
                href={data.social.youtube.url} target="_blank"
                rel="noopener noreferrer"></a>
            </li>
            <li>
              <a className="icon icon-xxs icon-primary fa fa-instagram" title={data.social.instagram.title}
                href={data.social.instagram.url} target="_blank"
                rel="noopener noreferrer"></a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;