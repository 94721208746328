const objectToQueryString = (obj) => {
  return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
};

const getFileFromApiResponse = (response) => {
  return response.arrayBuffer().then((buffer) => {
    let binary = '';
    const bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));

    return window.btoa(binary);
  });
};

const getBase64Flag = (fileType) => {
  return 'data:'.concat(fileType).concat(';base64,');
}

const MAX_WIDTH_IMG = 3000;
const MAX_HEIGHT_IMG = 3000;

const readFile = (file, width = MAX_WIDTH_IMG, height = MAX_HEIGHT_IMG) => {
  return new Promise(resolve => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      let base64ResizedImage = null;
      let img = new Image();
      img.src = reader.result;

      img.onload = () => {

        // Check if the image require resize at all
        if (img.height <= height && img.width <= width) {
          base64ResizedImage = reader.result;
        }
        else {
          // Make sure the width and height preserve the original aspect ratio and adjust if needed
          if (img.height > img.width) {
            width = Math.floor(height * (img.width / img.height));
          }
          else {
            height = Math.floor(width * (img.height / img.width));
          }

          let resizingCanvas = document.createElement('canvas');
          let resizingCanvasContext = resizingCanvas.getContext("2d");

          // Start with original image size
          resizingCanvas.width = img.width;
          resizingCanvas.height = img.height;


          // Draw the original image on the (temp) resizing canvas
          resizingCanvasContext.drawImage(img, 0, 0, resizingCanvas.width, resizingCanvas.height);

          let curImageDimensions = {
            width: Math.floor(img.width),
            height: Math.floor(img.height)
          };

          let halfImageDimensions = {
            width: null,
            height: null
          };

          // Quickly reduce the dize by 50% each time in few iterations until the size is less then
          // 2x time the target size - the motivation for it, is to reduce the aliasing that would have been
          // created with direct reduction of very big image to small image
          while (curImageDimensions.width * 0.5 > width) {
            // Reduce the resizing canvas by half and refresh the image
            halfImageDimensions.width = Math.floor(curImageDimensions.width * 0.5);
            halfImageDimensions.height = Math.floor(curImageDimensions.height * 0.5);

            resizingCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width, curImageDimensions.height,
              0, 0, halfImageDimensions.width, halfImageDimensions.height);

            curImageDimensions.width = halfImageDimensions.width;
            curImageDimensions.height = halfImageDimensions.height;
          }

          // Now do final resize for the resizingCanvas to meet the dimension requirments
          // directly to the output canvas, that will output the final image
          let outputCanvas = document.createElement('canvas');
          let outputCanvasContext = outputCanvas.getContext("2d");

          outputCanvas.width = width;
          outputCanvas.height = height;

          outputCanvasContext.drawImage(resizingCanvas, 0, 0, curImageDimensions.width, curImageDimensions.height,
            0, 0, width, height);

          // output the canvas pixels as an image. params: format, quality
          base64ResizedImage = outputCanvas.toDataURL('image/jpeg', 1);
        }

        resolve(base64ResizedImage);
      };
    }, false);

    reader.readAsDataURL(file);
  })
};

const formatPrice = (price) => {
  return Number(price).toLocaleString("es-ES", { minimumFractionDigits: 2 }) + ' €';
};

const addZero = (i) => {
  if (i < 10) {
    i = "0" + i;
  }

  return i;
};

const timestampToDatetime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = addZero(date.getHours());
  const min = addZero(date.getMinutes());
  const sec = addZero(date.getSeconds());

  return {
    date: `${day}/${month}/${year}`,
    time: `${hour}:${min}:${sec}`
  };
};

const constants = {
  HANGER_UNIT_PRICE: 2
};

const mapItemsToOrderLines = line => ({
  description: line.product.description,
  id: line.id,
  image: line.image,
  name: line.product.name,
  numberUnits: line.unitsNumber,
  hanger: line.hanger,
  productItem: {
    id: line.productItemId,
    unitPrice: line.unitPrice,
    title: line.title,
    height: line.height,
    width: line.width
  }
});

const getOrderTitle = orderData => {
  let title = '';
  let datetime = {};

  switch (orderData.status) {
    case 'IN_PROGRESS':
      datetime = timestampToDatetime(orderData.inProgressTimestamp);
      title = `Pedido en proceso desde el ${datetime.date} a las ${datetime.time}, por un importe total de ${formatPrice(orderData.totalAmount)}`;
      break;
    case 'SENDED':
      datetime = timestampToDatetime(orderData.sendedTimestamp);
      title = `Pedido enviado el ${datetime.date} a las ${datetime.time}, por un importe total de ${formatPrice(orderData.totalAmount)}`;
      break;
    case 'DELIVERED':
      datetime = timestampToDatetime(orderData.deliveredTimestamp);
      title = `Pedido entregado el ${datetime.date} a las ${datetime.time}, por un importe total de ${formatPrice(orderData.totalAmount)}`;
      break;
    case 'ORDERED':
    default:
      datetime = timestampToDatetime(orderData.orderedTimestamp);
      title = `Pedido pagado el ${datetime.date} a las ${datetime.time}, por un importe total de ${formatPrice(orderData.totalAmount)}`;
      break;
  }

  return title;
};

const getChangeStatusTitle = orderData => {
  let title = `El pedido de ${orderData.firstName} ${orderData.lastName}`;

  switch (orderData.status) {
    case 'IN_PROGRESS':
      title = `El pedido de ${orderData.firstName} ${orderData.lastName} está en estado en proceso y va a pasar a estado enviado.`;
      break;
    case 'SENDED':
      title = `El pedido de ${orderData.firstName} ${orderData.lastName} está en estado enviado y va a pasar a estado entregado.`;
      break;
    case 'ORDERED':
    default:
      title = `El pedido de ${orderData.firstName} ${orderData.lastName} está en estado pagado y va a pasar a estado en proceso.`;
      break;
  }

  return title.concat(' ¿Es correcto?');
};

export {
  objectToQueryString,
  getFileFromApiResponse,
  getBase64Flag,
  readFile,
  formatPrice,
  timestampToDatetime,
  constants,
  mapItemsToOrderLines,
  getOrderTitle,
  getChangeStatusTitle
};