import React, { useEffect } from 'react';
import api from '../../../../utils/api';
import { getChangeStatusTitle } from '../../../../utils/common';

const ModalConfirmNewStatus = props => {

  const updateOrderStatus = () => {
    let status = null;

    switch (props.order.status) {
      case 'ORDERED':
        status = 'IN_PROGRESS';
        break;
      case 'IN_PROGRESS':
        status = 'SENDED';
        break;
      case 'SENDED':
        status = 'DELIVERED';
        break;
      default:
        status = null;
        break;
    }

    api.post('orders/updateStatus', {
      id: props.order.id,
      status
    }).then(response => {
      props.onExit();
    })
  }

  useEffect(() => {

  }, [props.order]);

  return (
    <div className="modal fade" id="orderConfirmStatusModal" tabIndex="-1" role="dialog"
      aria-labelledby="orderDetailModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Confirmación cambio de estado del pedido</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {props.order !== null && (
              <div className="card card-custom py-4">
                <h6 className="card-custom-title">{getChangeStatusTitle(props.order)}</h6>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 text-right">
                  <button type="button" className="button button-black button-shadow" onClick={() => updateOrderStatus()} data-dismiss="modal">Sí</button>
                </div>
                <div className="col-md-6">
                  <button type="button" className="button button-black button-shadow" data-dismiss="modal">No</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmNewStatus;