import React from 'react';
import loadjs from 'loadjs';
import env from '../../environments';
import Header from '../../commons/Header';
import PreFooter from '../../commons/PreFooter';
import Footer from '../../commons/Footer';
import ProductTitle from './partials/ProductTitle';
import ProductDetailInfo from './partials/ProductDetailInfo';
import ProductDetailImage from './partials/ProductDetailImage';

class ProductDetail extends React.Component {

  componentDidMount() {
    loadjs(env.BASE_ASSETS_URL.concat('js/script.js'), () => {
      window.scrollTo(0, 0);
    });
  }

  render() {
    return (
      <div className="page">
        <Header />
        <ProductTitle product={this.props.location.params.product} />
        <ProductDetailInfo product={this.props.location.params.product} />
        <ProductDetailImage product={this.props.location.params.product} />
        <PreFooter />
        <Footer />
      </div>
    );
  }
}

export default ProductDetail;